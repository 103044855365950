@import 'utilities/variables'

// ===========================================//
// Main auto-complete styles
// ===========================================//
.autocomplete-container
  @media screen and (max-width: 512px)
    width: 100%
  .mat-input-wrapper
    width: 100%
  .mat-mdc-form-field-infix
    display: flex
    min-width: 180px
    width: auto

.autocomplete-input
  line-height: 14px
  color: $bbraun-gray-80
  min-width: 175px
  @media screen and (max-width: 512px)
    width: calc(100% - 30px)

.autocomplete

    .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled)
      color: $bbraun-gray-80

    .mat-option.selected-opt
      color: $bbraun-active !important

    .opt
      color: $bbraun-gray-80

      .mat-option-text
        display: flex
        justify-content: space-between

.autocomplete-action-icon
  color: $bbraun-gray-80
