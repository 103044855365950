/* Generate font sizes */

@mixin generate-sizes($class, $extra-small, $small, $medium, $large, $extra-large) 
    .#{$class}-xs   
        font-size: $extra-small !important

    .#{$class}-sm  
        font-size: $small !important
    
    .#{$class}-md  
        font-size: $medium !important
    
    .#{$class}-lg  
        font-size: $large !important
    
    .#{$class}-xlg 
        font-size: $extra-large !important
