@import 'utilities/variables'

.mat-mdc-tab-group
  .mat-mdc-tab-header
    top: 100px
    position: sticky
    z-index: 10
    display: none !important

.entities-tab-content .mat-mdc-tab-body-wrapper
  overflow: visible !important

.entities-tab-content .mat-mdc-tab-body-active
  overflow: visible !important

.entities-tab-content .mat-mdc-tab-body-content
  overflow: visible !important

.entities-tab-content .fixed-under-tab-label
  display: none !important
