@import 'utilities/variables'
@import 'mixins'

// Generate global font-sizes
@include generate-sizes("font", 12px, 14px, 18px, 23px, 30px)

// Resets
html, body, pre
    font-family: $bbraun-font-family

body
    color: #333333
    margin: 0
    padding: 0

a
    color: $bbraun-non-clickable
    text-decoration: none
    &:hover, &:focus
        color: $bbraun-non-clickable
        text-decoration: none

ul
    list-style: none
    margin: 0
    padding: 0

// Color styles
.red
    color: $bbraun-error !important

.green
    color: $bbraun-active !important

.grey
    color: $bbraun-gray-80 !important

.grey-hover
    color: $bbraun-gray-90

    &:hover
        color: $bbraun-gray-90

.container
    max-width: 1140px !important
    padding-left: 0 !important
    padding-right: 0 !important

    // 1024px + 10%
    @media screen and (max-width: 1126.4px)
        padding-left: 3% !important
        padding-right: 3% !important

.form-input
    width: 300px

.entities-input-margin
    margin-right: 50px
    @media screen and (max-width: 750px)
        margin: 0

.opacity-0-5
    opacity: .5
