@import 'utilities/variables'

// Spinner
.mw-12
  max-width: 12%

.mw-15
  max-width: 15%

.mw-16
  max-width: 16%

.mw-18
  max-width: 18%

.mw-20
  max-width: 20%

.mw-21
  max-width: 21%

.mw-23
  max-width: 23%

.mw-25
  max-width: 25%

.mw-33
  max-width: 33%

.mw-35
  max-width: 35%

.mw-40
  max-width: 40%

.table-indicator-icon
  max-width: calc(40px + 2%)

// New Table - do not delete

.action-column-styles
  max-width: 65px

.actions-column-styles
  max-width: 110px

.remove-icon-from-max-width
  max-width: calc(100% - 32px) !important
