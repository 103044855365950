@charset "UTF-8";
/* Generate font sizes */
/* stylelint-disable scss/dollar-variable-empty-line-before */
/* Generate font sizes */
.font-xs {
  font-size: 12px !important;
}

.font-sm {
  font-size: 14px !important;
}

.font-md {
  font-size: 18px !important;
}

.font-lg {
  font-size: 23px !important;
}

.font-xlg {
  font-size: 30px !important;
}

html, body, pre {
  font-family: arial, "Helvetica Neue", helvetica, sans-serif;
}

body {
  color: #333333;
  margin: 0;
  padding: 0;
}

a {
  color: #202020;
  text-decoration: none;
}
a:hover, a:focus {
  color: #202020;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.red {
  color: #eb0000 !important;
}

.green {
  color: #00a97a !important;
}

.grey {
  color: #737373 !important;
}

.grey-hover {
  color: #636363;
}
.grey-hover:hover {
  color: #636363;
}

.container {
  max-width: 1140px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
@media screen and (max-width: 1126.4px) {
  .container {
    padding-left: 3% !important;
    padding-right: 3% !important;
  }
}

.form-input {
  width: 300px;
}

.entities-input-margin {
  margin-right: 50px;
}
@media screen and (max-width: 750px) {
  .entities-input-margin {
    margin: 0;
  }
}

.opacity-0-5 {
  opacity: 0.5;
}

/* stylelint-disable scss/dollar-variable-empty-line-before */
mat-select-trigger {
  color: #737373;
}

.mat-option-text {
  color: #737373;
}

.mat-select-trigger .mat-select-arrow-wrapper {
  visibility: hidden;
}

.mat-select-value {
  color: #737373 !important;
}

.mat-calendar-header .mat-icon-button {
  border: 0 !important;
}

.mat-calendar-header .mat-button {
  border: 0 !important;
}

.mat-card:hover {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}

.mat-form-field-label-wrapper .mat-form-field-label {
  line-height: 16px;
}

/* stylelint-disable scss/dollar-variable-empty-line-before */
.mw-12 {
  max-width: 12%;
}

.mw-15 {
  max-width: 15%;
}

.mw-16 {
  max-width: 16%;
}

.mw-18 {
  max-width: 18%;
}

.mw-20 {
  max-width: 20%;
}

.mw-21 {
  max-width: 21%;
}

.mw-23 {
  max-width: 23%;
}

.mw-25 {
  max-width: 25%;
}

.mw-33 {
  max-width: 33%;
}

.mw-35 {
  max-width: 35%;
}

.mw-40 {
  max-width: 40%;
}

.table-indicator-icon {
  max-width: calc(40px + 2%);
}

.action-column-styles {
  max-width: 65px;
}

.actions-column-styles {
  max-width: 110px;
}

.remove-icon-from-max-width {
  max-width: calc(100% - 32px) !important;
}

/* stylelint-disable scss/dollar-variable-empty-line-before */
@media screen and (max-width: 512px) {
  .autocomplete-container {
    width: 100%;
  }
}
.autocomplete-container .mat-input-wrapper {
  width: 100%;
}
.autocomplete-container .mat-mdc-form-field-infix {
  display: flex;
  min-width: 180px;
  width: auto;
}

.autocomplete-input {
  line-height: 14px;
  color: #737373;
  min-width: 175px;
}
@media screen and (max-width: 512px) {
  .autocomplete-input {
    width: calc(100% - 30px);
  }
}

.autocomplete .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: #737373;
}
.autocomplete .mat-option.selected-opt {
  color: #00a97a !important;
}
.autocomplete .opt {
  color: #737373;
}
.autocomplete .opt .mat-option-text {
  display: flex;
  justify-content: space-between;
}

.autocomplete-action-icon {
  color: #737373;
}

/* stylelint-disable scss/dollar-variable-empty-line-before */
.mat-mdc-tab-group .mat-mdc-tab-header {
  top: 100px;
  position: sticky;
  z-index: 10;
  display: none !important;
}

.entities-tab-content .mat-mdc-tab-body-wrapper {
  overflow: visible !important;
}

.entities-tab-content .mat-mdc-tab-body-active {
  overflow: visible !important;
}

.entities-tab-content .mat-mdc-tab-body-content {
  overflow: visible !important;
}

.entities-tab-content .fixed-under-tab-label {
  display: none !important;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.eot"); /* For IE6-8 */
  src: local("☺"), url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff2") format("woff2"), url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff") format("woff"), url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.ttf") format("truetype");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  /* Respect document layout direction */
  direction: inherit;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}
.material-icons._10k:before {
  content: "\e951";
}
.material-icons._10mp:before {
  content: "\e952";
}
.material-icons._11mp:before {
  content: "\e953";
}
.material-icons._123:before {
  content: "\eb8d";
}
.material-icons._12mp:before {
  content: "\e954";
}
.material-icons._13mp:before {
  content: "\e955";
}
.material-icons._14mp:before {
  content: "\e956";
}
.material-icons._15mp:before {
  content: "\e957";
}
.material-icons._16mp:before {
  content: "\e958";
}
.material-icons._17mp:before {
  content: "\e959";
}
.material-icons._18_up_rating:before {
  content: "\f8fd";
}
.material-icons._18mp:before {
  content: "\e95a";
}
.material-icons._19mp:before {
  content: "\e95b";
}
.material-icons._1k:before {
  content: "\e95c";
}
.material-icons._1k_plus:before {
  content: "\e95d";
}
.material-icons._1x_mobiledata:before {
  content: "\efcd";
}
.material-icons._20mp:before {
  content: "\e95e";
}
.material-icons._21mp:before {
  content: "\e95f";
}
.material-icons._22mp:before {
  content: "\e960";
}
.material-icons._23mp:before {
  content: "\e961";
}
.material-icons._24mp:before {
  content: "\e962";
}
.material-icons._2k:before {
  content: "\e963";
}
.material-icons._2k_plus:before {
  content: "\e964";
}
.material-icons._2mp:before {
  content: "\e965";
}
.material-icons._30fps:before {
  content: "\efce";
}
.material-icons._30fps_select:before {
  content: "\efcf";
}
.material-icons._360:before {
  content: "\e577";
}
.material-icons._3d_rotation:before {
  content: "\e84d";
}
.material-icons._3g_mobiledata:before {
  content: "\efd0";
}
.material-icons._3k:before {
  content: "\e966";
}
.material-icons._3k_plus:before {
  content: "\e967";
}
.material-icons._3mp:before {
  content: "\e968";
}
.material-icons._3p:before {
  content: "\efd1";
}
.material-icons._4g_mobiledata:before {
  content: "\efd2";
}
.material-icons._4g_plus_mobiledata:before {
  content: "\efd3";
}
.material-icons._4k:before {
  content: "\e072";
}
.material-icons._4k_plus:before {
  content: "\e969";
}
.material-icons._4mp:before {
  content: "\e96a";
}
.material-icons._5g:before {
  content: "\ef38";
}
.material-icons._5k:before {
  content: "\e96b";
}
.material-icons._5k_plus:before {
  content: "\e96c";
}
.material-icons._5mp:before {
  content: "\e96d";
}
.material-icons._60fps:before {
  content: "\efd4";
}
.material-icons._60fps_select:before {
  content: "\efd5";
}
.material-icons._6_ft_apart:before {
  content: "\f21e";
}
.material-icons._6k:before {
  content: "\e96e";
}
.material-icons._6k_plus:before {
  content: "\e96f";
}
.material-icons._6mp:before {
  content: "\e970";
}
.material-icons._7k:before {
  content: "\e971";
}
.material-icons._7k_plus:before {
  content: "\e972";
}
.material-icons._7mp:before {
  content: "\e973";
}
.material-icons._8k:before {
  content: "\e974";
}
.material-icons._8k_plus:before {
  content: "\e975";
}
.material-icons._8mp:before {
  content: "\e976";
}
.material-icons._9k:before {
  content: "\e977";
}
.material-icons._9k_plus:before {
  content: "\e978";
}
.material-icons._9mp:before {
  content: "\e979";
}
.material-icons.abc:before {
  content: "\eb94";
}
.material-icons.ac_unit:before {
  content: "\eb3b";
}
.material-icons.access_alarm:before {
  content: "\e190";
}
.material-icons.access_alarms:before {
  content: "\e191";
}
.material-icons.access_time:before {
  content: "\e192";
}
.material-icons.access_time_filled:before {
  content: "\efd6";
}
.material-icons.accessibility:before {
  content: "\e84e";
}
.material-icons.accessibility_new:before {
  content: "\e92c";
}
.material-icons.accessible:before {
  content: "\e914";
}
.material-icons.accessible_forward:before {
  content: "\e934";
}
.material-icons.account_balance:before {
  content: "\e84f";
}
.material-icons.account_balance_wallet:before {
  content: "\e850";
}
.material-icons.account_box:before {
  content: "\e851";
}
.material-icons.account_circle:before {
  content: "\e853";
}
.material-icons.account_tree:before {
  content: "\e97a";
}
.material-icons.ad_units:before {
  content: "\ef39";
}
.material-icons.adb:before {
  content: "\e60e";
}
.material-icons.add:before {
  content: "\e145";
}
.material-icons.add_a_photo:before {
  content: "\e439";
}
.material-icons.add_alarm:before {
  content: "\e193";
}
.material-icons.add_alert:before {
  content: "\e003";
}
.material-icons.add_box:before {
  content: "\e146";
}
.material-icons.add_business:before {
  content: "\e729";
}
.material-icons.add_call:before {
  content: "\e0e8";
}
.material-icons.add_card:before {
  content: "\eb86";
}
.material-icons.add_chart:before {
  content: "\e97b";
}
.material-icons.add_circle:before {
  content: "\e147";
}
.material-icons.add_circle_outline:before {
  content: "\e148";
}
.material-icons.add_comment:before {
  content: "\e266";
}
.material-icons.add_home:before {
  content: "\f8eb";
}
.material-icons.add_home_work:before {
  content: "\f8ed";
}
.material-icons.add_ic_call:before {
  content: "\e97c";
}
.material-icons.add_link:before {
  content: "\e178";
}
.material-icons.add_location:before {
  content: "\e567";
}
.material-icons.add_location_alt:before {
  content: "\ef3a";
}
.material-icons.add_moderator:before {
  content: "\e97d";
}
.material-icons.add_photo_alternate:before {
  content: "\e43e";
}
.material-icons.add_reaction:before {
  content: "\e1d3";
}
.material-icons.add_road:before {
  content: "\ef3b";
}
.material-icons.add_shopping_cart:before {
  content: "\e854";
}
.material-icons.add_task:before {
  content: "\f23a";
}
.material-icons.add_to_drive:before {
  content: "\e65c";
}
.material-icons.add_to_home_screen:before {
  content: "\e1fe";
}
.material-icons.add_to_photos:before {
  content: "\e39d";
}
.material-icons.add_to_queue:before {
  content: "\e05c";
}
.material-icons.addchart:before {
  content: "\ef3c";
}
.material-icons.adf_scanner:before {
  content: "\eada";
}
.material-icons.adjust:before {
  content: "\e39e";
}
.material-icons.admin_panel_settings:before {
  content: "\ef3d";
}
.material-icons.adobe:before {
  content: "\ea96";
}
.material-icons.ads_click:before {
  content: "\e762";
}
.material-icons.agriculture:before {
  content: "\ea79";
}
.material-icons.air:before {
  content: "\efd8";
}
.material-icons.airline_seat_flat:before {
  content: "\e630";
}
.material-icons.airline_seat_flat_angled:before {
  content: "\e631";
}
.material-icons.airline_seat_individual_suite:before {
  content: "\e632";
}
.material-icons.airline_seat_legroom_extra:before {
  content: "\e633";
}
.material-icons.airline_seat_legroom_normal:before {
  content: "\e634";
}
.material-icons.airline_seat_legroom_reduced:before {
  content: "\e635";
}
.material-icons.airline_seat_recline_extra:before {
  content: "\e636";
}
.material-icons.airline_seat_recline_normal:before {
  content: "\e637";
}
.material-icons.airline_stops:before {
  content: "\e7d0";
}
.material-icons.airlines:before {
  content: "\e7ca";
}
.material-icons.airplane_ticket:before {
  content: "\efd9";
}
.material-icons.airplanemode_active:before {
  content: "\e195";
}
.material-icons.airplanemode_inactive:before {
  content: "\e194";
}
.material-icons.airplanemode_off:before {
  content: "\e194";
}
.material-icons.airplanemode_on:before {
  content: "\e195";
}
.material-icons.airplay:before {
  content: "\e055";
}
.material-icons.airport_shuttle:before {
  content: "\eb3c";
}
.material-icons.alarm:before {
  content: "\e855";
}
.material-icons.alarm_add:before {
  content: "\e856";
}
.material-icons.alarm_off:before {
  content: "\e857";
}
.material-icons.alarm_on:before {
  content: "\e858";
}
.material-icons.album:before {
  content: "\e019";
}
.material-icons.align_horizontal_center:before {
  content: "\e00f";
}
.material-icons.align_horizontal_left:before {
  content: "\e00d";
}
.material-icons.align_horizontal_right:before {
  content: "\e010";
}
.material-icons.align_vertical_bottom:before {
  content: "\e015";
}
.material-icons.align_vertical_center:before {
  content: "\e011";
}
.material-icons.align_vertical_top:before {
  content: "\e00c";
}
.material-icons.all_inbox:before {
  content: "\e97f";
}
.material-icons.all_inclusive:before {
  content: "\eb3d";
}
.material-icons.all_out:before {
  content: "\e90b";
}
.material-icons.alt_route:before {
  content: "\f184";
}
.material-icons.alternate_email:before {
  content: "\e0e6";
}
.material-icons.amp_stories:before {
  content: "\ea13";
}
.material-icons.analytics:before {
  content: "\ef3e";
}
.material-icons.anchor:before {
  content: "\f1cd";
}
.material-icons.android:before {
  content: "\e859";
}
.material-icons.animation:before {
  content: "\e71c";
}
.material-icons.announcement:before {
  content: "\e85a";
}
.material-icons.aod:before {
  content: "\efda";
}
.material-icons.apartment:before {
  content: "\ea40";
}
.material-icons.api:before {
  content: "\f1b7";
}
.material-icons.app_blocking:before {
  content: "\ef3f";
}
.material-icons.app_registration:before {
  content: "\ef40";
}
.material-icons.app_settings_alt:before {
  content: "\ef41";
}
.material-icons.app_shortcut:before {
  content: "\eae4";
}
.material-icons.apple:before {
  content: "\ea80";
}
.material-icons.approval:before {
  content: "\e982";
}
.material-icons.apps:before {
  content: "\e5c3";
}
.material-icons.apps_outage:before {
  content: "\e7cc";
}
.material-icons.architecture:before {
  content: "\ea3b";
}
.material-icons.archive:before {
  content: "\e149";
}
.material-icons.area_chart:before {
  content: "\e770";
}
.material-icons.arrow_back:before {
  content: "\e5c4";
}
.material-icons.arrow_back_ios:before {
  content: "\e5e0";
}
.material-icons.arrow_back_ios_new:before {
  content: "\e2ea";
}
.material-icons.arrow_circle_down:before {
  content: "\f181";
}
.material-icons.arrow_circle_left:before {
  content: "\eaa7";
}
.material-icons.arrow_circle_right:before {
  content: "\eaaa";
}
.material-icons.arrow_circle_up:before {
  content: "\f182";
}
.material-icons.arrow_downward:before {
  content: "\e5db";
}
.material-icons.arrow_drop_down:before {
  content: "\e5c5";
}
.material-icons.arrow_drop_down_circle:before {
  content: "\e5c6";
}
.material-icons.arrow_drop_up:before {
  content: "\e5c7";
}
.material-icons.arrow_forward:before {
  content: "\e5c8";
}
.material-icons.arrow_forward_ios:before {
  content: "\e5e1";
}
.material-icons.arrow_left:before {
  content: "\e5de";
}
.material-icons.arrow_right:before {
  content: "\e5df";
}
.material-icons.arrow_right_alt:before {
  content: "\e941";
}
.material-icons.arrow_upward:before {
  content: "\e5d8";
}
.material-icons.art_track:before {
  content: "\e060";
}
.material-icons.article:before {
  content: "\ef42";
}
.material-icons.aspect_ratio:before {
  content: "\e85b";
}
.material-icons.assessment:before {
  content: "\e85c";
}
.material-icons.assignment:before {
  content: "\e85d";
}
.material-icons.assignment_ind:before {
  content: "\e85e";
}
.material-icons.assignment_late:before {
  content: "\e85f";
}
.material-icons.assignment_return:before {
  content: "\e860";
}
.material-icons.assignment_returned:before {
  content: "\e861";
}
.material-icons.assignment_turned_in:before {
  content: "\e862";
}
.material-icons.assistant:before {
  content: "\e39f";
}
.material-icons.assistant_direction:before {
  content: "\e988";
}
.material-icons.assistant_navigation:before {
  content: "\e989";
}
.material-icons.assistant_photo:before {
  content: "\e3a0";
}
.material-icons.assured_workload:before {
  content: "\eb6f";
}
.material-icons.atm:before {
  content: "\e573";
}
.material-icons.attach_email:before {
  content: "\ea5e";
}
.material-icons.attach_file:before {
  content: "\e226";
}
.material-icons.attach_money:before {
  content: "\e227";
}
.material-icons.attachment:before {
  content: "\e2bc";
}
.material-icons.attractions:before {
  content: "\ea52";
}
.material-icons.attribution:before {
  content: "\efdb";
}
.material-icons.audio_file:before {
  content: "\eb82";
}
.material-icons.audiotrack:before {
  content: "\e3a1";
}
.material-icons.auto_awesome:before {
  content: "\e65f";
}
.material-icons.auto_awesome_mosaic:before {
  content: "\e660";
}
.material-icons.auto_awesome_motion:before {
  content: "\e661";
}
.material-icons.auto_delete:before {
  content: "\ea4c";
}
.material-icons.auto_fix_high:before {
  content: "\e663";
}
.material-icons.auto_fix_normal:before {
  content: "\e664";
}
.material-icons.auto_fix_off:before {
  content: "\e665";
}
.material-icons.auto_graph:before {
  content: "\e4fb";
}
.material-icons.auto_mode:before {
  content: "\ec20";
}
.material-icons.auto_stories:before {
  content: "\e666";
}
.material-icons.autofps_select:before {
  content: "\efdc";
}
.material-icons.autorenew:before {
  content: "\e863";
}
.material-icons.av_timer:before {
  content: "\e01b";
}
.material-icons.baby_changing_station:before {
  content: "\f19b";
}
.material-icons.back_hand:before {
  content: "\e764";
}
.material-icons.backpack:before {
  content: "\f19c";
}
.material-icons.backspace:before {
  content: "\e14a";
}
.material-icons.backup:before {
  content: "\e864";
}
.material-icons.backup_table:before {
  content: "\ef43";
}
.material-icons.badge:before {
  content: "\ea67";
}
.material-icons.bakery_dining:before {
  content: "\ea53";
}
.material-icons.balance:before {
  content: "\eaf6";
}
.material-icons.balcony:before {
  content: "\e58f";
}
.material-icons.ballot:before {
  content: "\e172";
}
.material-icons.bar_chart:before {
  content: "\e26b";
}
.material-icons.batch_prediction:before {
  content: "\f0f5";
}
.material-icons.bathroom:before {
  content: "\efdd";
}
.material-icons.bathtub:before {
  content: "\ea41";
}
.material-icons.battery_0_bar:before {
  content: "\ebdc";
}
.material-icons.battery_1_bar:before {
  content: "\ebd9";
}
.material-icons.battery_2_bar:before {
  content: "\ebe0";
}
.material-icons.battery_3_bar:before {
  content: "\ebdd";
}
.material-icons.battery_4_bar:before {
  content: "\ebe2";
}
.material-icons.battery_5_bar:before {
  content: "\ebd4";
}
.material-icons.battery_6_bar:before {
  content: "\ebd2";
}
.material-icons.battery_alert:before {
  content: "\e19c";
}
.material-icons.battery_charging_full:before {
  content: "\e1a3";
}
.material-icons.battery_full:before {
  content: "\e1a4";
}
.material-icons.battery_saver:before {
  content: "\efde";
}
.material-icons.battery_std:before {
  content: "\e1a5";
}
.material-icons.battery_unknown:before {
  content: "\e1a6";
}
.material-icons.beach_access:before {
  content: "\eb3e";
}
.material-icons.bed:before {
  content: "\efdf";
}
.material-icons.bedroom_baby:before {
  content: "\efe0";
}
.material-icons.bedroom_child:before {
  content: "\efe1";
}
.material-icons.bedroom_parent:before {
  content: "\efe2";
}
.material-icons.bedtime:before {
  content: "\ef44";
}
.material-icons.bedtime_off:before {
  content: "\eb76";
}
.material-icons.beenhere:before {
  content: "\e52d";
}
.material-icons.bento:before {
  content: "\f1f4";
}
.material-icons.bike_scooter:before {
  content: "\ef45";
}
.material-icons.biotech:before {
  content: "\ea3a";
}
.material-icons.blender:before {
  content: "\efe3";
}
.material-icons.blinds:before {
  content: "\e286";
}
.material-icons.blinds_closed:before {
  content: "\ec1f";
}
.material-icons.block:before {
  content: "\e14b";
}
.material-icons.block_flipped:before {
  content: "\ef46";
}
.material-icons.bloodtype:before {
  content: "\efe4";
}
.material-icons.bluetooth:before {
  content: "\e1a7";
}
.material-icons.bluetooth_audio:before {
  content: "\e60f";
}
.material-icons.bluetooth_connected:before {
  content: "\e1a8";
}
.material-icons.bluetooth_disabled:before {
  content: "\e1a9";
}
.material-icons.bluetooth_drive:before {
  content: "\efe5";
}
.material-icons.bluetooth_searching:before {
  content: "\e1aa";
}
.material-icons.blur_circular:before {
  content: "\e3a2";
}
.material-icons.blur_linear:before {
  content: "\e3a3";
}
.material-icons.blur_off:before {
  content: "\e3a4";
}
.material-icons.blur_on:before {
  content: "\e3a5";
}
.material-icons.bolt:before {
  content: "\ea0b";
}
.material-icons.book:before {
  content: "\e865";
}
.material-icons.book_online:before {
  content: "\f217";
}
.material-icons.bookmark:before {
  content: "\e866";
}
.material-icons.bookmark_add:before {
  content: "\e598";
}
.material-icons.bookmark_added:before {
  content: "\e599";
}
.material-icons.bookmark_border:before {
  content: "\e867";
}
.material-icons.bookmark_outline:before {
  content: "\e867";
}
.material-icons.bookmark_remove:before {
  content: "\e59a";
}
.material-icons.bookmarks:before {
  content: "\e98b";
}
.material-icons.border_all:before {
  content: "\e228";
}
.material-icons.border_bottom:before {
  content: "\e229";
}
.material-icons.border_clear:before {
  content: "\e22a";
}
.material-icons.border_color:before {
  content: "\e22b";
}
.material-icons.border_horizontal:before {
  content: "\e22c";
}
.material-icons.border_inner:before {
  content: "\e22d";
}
.material-icons.border_left:before {
  content: "\e22e";
}
.material-icons.border_outer:before {
  content: "\e22f";
}
.material-icons.border_right:before {
  content: "\e230";
}
.material-icons.border_style:before {
  content: "\e231";
}
.material-icons.border_top:before {
  content: "\e232";
}
.material-icons.border_vertical:before {
  content: "\e233";
}
.material-icons.boy:before {
  content: "\eb67";
}
.material-icons.branding_watermark:before {
  content: "\e06b";
}
.material-icons.breakfast_dining:before {
  content: "\ea54";
}
.material-icons.brightness_1:before {
  content: "\e3a6";
}
.material-icons.brightness_2:before {
  content: "\e3a7";
}
.material-icons.brightness_3:before {
  content: "\e3a8";
}
.material-icons.brightness_4:before {
  content: "\e3a9";
}
.material-icons.brightness_5:before {
  content: "\e3aa";
}
.material-icons.brightness_6:before {
  content: "\e3ab";
}
.material-icons.brightness_7:before {
  content: "\e3ac";
}
.material-icons.brightness_auto:before {
  content: "\e1ab";
}
.material-icons.brightness_high:before {
  content: "\e1ac";
}
.material-icons.brightness_low:before {
  content: "\e1ad";
}
.material-icons.brightness_medium:before {
  content: "\e1ae";
}
.material-icons.broadcast_on_home:before {
  content: "\f8f8";
}
.material-icons.broadcast_on_personal:before {
  content: "\f8f9";
}
.material-icons.broken_image:before {
  content: "\e3ad";
}
.material-icons.browse_gallery:before {
  content: "\ebd1";
}
.material-icons.browser_not_supported:before {
  content: "\ef47";
}
.material-icons.browser_updated:before {
  content: "\e7cf";
}
.material-icons.brunch_dining:before {
  content: "\ea73";
}
.material-icons.brush:before {
  content: "\e3ae";
}
.material-icons.bubble_chart:before {
  content: "\e6dd";
}
.material-icons.bug_report:before {
  content: "\e868";
}
.material-icons.build:before {
  content: "\e869";
}
.material-icons.build_circle:before {
  content: "\ef48";
}
.material-icons.bungalow:before {
  content: "\e591";
}
.material-icons.burst_mode:before {
  content: "\e43c";
}
.material-icons.bus_alert:before {
  content: "\e98f";
}
.material-icons.business:before {
  content: "\e0af";
}
.material-icons.business_center:before {
  content: "\eb3f";
}
.material-icons.cabin:before {
  content: "\e589";
}
.material-icons.cable:before {
  content: "\efe6";
}
.material-icons.cached:before {
  content: "\e86a";
}
.material-icons.cake:before {
  content: "\e7e9";
}
.material-icons.calculate:before {
  content: "\ea5f";
}
.material-icons.calendar_month:before {
  content: "\ebcc";
}
.material-icons.calendar_today:before {
  content: "\e935";
}
.material-icons.calendar_view_day:before {
  content: "\e936";
}
.material-icons.calendar_view_month:before {
  content: "\efe7";
}
.material-icons.calendar_view_week:before {
  content: "\efe8";
}
.material-icons.call:before {
  content: "\e0b0";
}
.material-icons.call_end:before {
  content: "\e0b1";
}
.material-icons.call_made:before {
  content: "\e0b2";
}
.material-icons.call_merge:before {
  content: "\e0b3";
}
.material-icons.call_missed:before {
  content: "\e0b4";
}
.material-icons.call_missed_outgoing:before {
  content: "\e0e4";
}
.material-icons.call_received:before {
  content: "\e0b5";
}
.material-icons.call_split:before {
  content: "\e0b6";
}
.material-icons.call_to_action:before {
  content: "\e06c";
}
.material-icons.camera:before {
  content: "\e3af";
}
.material-icons.camera_alt:before {
  content: "\e3b0";
}
.material-icons.camera_enhance:before {
  content: "\e8fc";
}
.material-icons.camera_front:before {
  content: "\e3b1";
}
.material-icons.camera_indoor:before {
  content: "\efe9";
}
.material-icons.camera_outdoor:before {
  content: "\efea";
}
.material-icons.camera_rear:before {
  content: "\e3b2";
}
.material-icons.camera_roll:before {
  content: "\e3b3";
}
.material-icons.cameraswitch:before {
  content: "\efeb";
}
.material-icons.campaign:before {
  content: "\ef49";
}
.material-icons.cancel:before {
  content: "\e5c9";
}
.material-icons.cancel_presentation:before {
  content: "\e0e9";
}
.material-icons.cancel_schedule_send:before {
  content: "\ea39";
}
.material-icons.candlestick_chart:before {
  content: "\ead4";
}
.material-icons.car_crash:before {
  content: "\ebf2";
}
.material-icons.car_rental:before {
  content: "\ea55";
}
.material-icons.car_repair:before {
  content: "\ea56";
}
.material-icons.card_giftcard:before {
  content: "\e8f6";
}
.material-icons.card_membership:before {
  content: "\e8f7";
}
.material-icons.card_travel:before {
  content: "\e8f8";
}
.material-icons.carpenter:before {
  content: "\f1f8";
}
.material-icons.cases:before {
  content: "\e992";
}
.material-icons.casino:before {
  content: "\eb40";
}
.material-icons.cast:before {
  content: "\e307";
}
.material-icons.cast_connected:before {
  content: "\e308";
}
.material-icons.cast_for_education:before {
  content: "\efec";
}
.material-icons.castle:before {
  content: "\eab1";
}
.material-icons.catching_pokemon:before {
  content: "\e508";
}
.material-icons.category:before {
  content: "\e574";
}
.material-icons.celebration:before {
  content: "\ea65";
}
.material-icons.cell_tower:before {
  content: "\ebba";
}
.material-icons.cell_wifi:before {
  content: "\e0ec";
}
.material-icons.center_focus_strong:before {
  content: "\e3b4";
}
.material-icons.center_focus_weak:before {
  content: "\e3b5";
}
.material-icons.chair:before {
  content: "\efed";
}
.material-icons.chair_alt:before {
  content: "\efee";
}
.material-icons.chalet:before {
  content: "\e585";
}
.material-icons.change_circle:before {
  content: "\e2e7";
}
.material-icons.change_history:before {
  content: "\e86b";
}
.material-icons.charging_station:before {
  content: "\f19d";
}
.material-icons.chat:before {
  content: "\e0b7";
}
.material-icons.chat_bubble:before {
  content: "\e0ca";
}
.material-icons.chat_bubble_outline:before {
  content: "\e0cb";
}
.material-icons.check:before {
  content: "\e5ca";
}
.material-icons.check_box:before {
  content: "\e834";
}
.material-icons.check_box_outline_blank:before {
  content: "\e835";
}
.material-icons.check_circle:before {
  content: "\e86c";
}
.material-icons.check_circle_outline:before {
  content: "\e92d";
}
.material-icons.checklist:before {
  content: "\e6b1";
}
.material-icons.checklist_rtl:before {
  content: "\e6b3";
}
.material-icons.checkroom:before {
  content: "\f19e";
}
.material-icons.chevron_left:before {
  content: "\e5cb";
}
.material-icons.chevron_right:before {
  content: "\e5cc";
}
.material-icons.child_care:before {
  content: "\eb41";
}
.material-icons.child_friendly:before {
  content: "\eb42";
}
.material-icons.chrome_reader_mode:before {
  content: "\e86d";
}
.material-icons.church:before {
  content: "\eaae";
}
.material-icons.circle:before {
  content: "\ef4a";
}
.material-icons.circle_notifications:before {
  content: "\e994";
}
.material-icons.class:before {
  content: "\e86e";
}
.material-icons.clean_hands:before {
  content: "\f21f";
}
.material-icons.cleaning_services:before {
  content: "\f0ff";
}
.material-icons.clear:before {
  content: "\e14c";
}
.material-icons.clear_all:before {
  content: "\e0b8";
}
.material-icons.close:before {
  content: "\e5cd";
}
.material-icons.close_fullscreen:before {
  content: "\f1cf";
}
.material-icons.closed_caption:before {
  content: "\e01c";
}
.material-icons.closed_caption_disabled:before {
  content: "\f1dc";
}
.material-icons.closed_caption_off:before {
  content: "\e996";
}
.material-icons.cloud:before {
  content: "\e2bd";
}
.material-icons.cloud_circle:before {
  content: "\e2be";
}
.material-icons.cloud_done:before {
  content: "\e2bf";
}
.material-icons.cloud_download:before {
  content: "\e2c0";
}
.material-icons.cloud_off:before {
  content: "\e2c1";
}
.material-icons.cloud_queue:before {
  content: "\e2c2";
}
.material-icons.cloud_sync:before {
  content: "\eb5a";
}
.material-icons.cloud_upload:before {
  content: "\e2c3";
}
.material-icons.cloudy_snowing:before {
  content: "\e810";
}
.material-icons.co2:before {
  content: "\e7b0";
}
.material-icons.co_present:before {
  content: "\eaf0";
}
.material-icons.code:before {
  content: "\e86f";
}
.material-icons.code_off:before {
  content: "\e4f3";
}
.material-icons.coffee:before {
  content: "\efef";
}
.material-icons.coffee_maker:before {
  content: "\eff0";
}
.material-icons.collections:before {
  content: "\e3b6";
}
.material-icons.collections_bookmark:before {
  content: "\e431";
}
.material-icons.color_lens:before {
  content: "\e3b7";
}
.material-icons.colorize:before {
  content: "\e3b8";
}
.material-icons.comment:before {
  content: "\e0b9";
}
.material-icons.comment_bank:before {
  content: "\ea4e";
}
.material-icons.comments_disabled:before {
  content: "\e7a2";
}
.material-icons.commit:before {
  content: "\eaf5";
}
.material-icons.commute:before {
  content: "\e940";
}
.material-icons.compare:before {
  content: "\e3b9";
}
.material-icons.compare_arrows:before {
  content: "\e915";
}
.material-icons.compass_calibration:before {
  content: "\e57c";
}
.material-icons.compost:before {
  content: "\e761";
}
.material-icons.compress:before {
  content: "\e94d";
}
.material-icons.computer:before {
  content: "\e30a";
}
.material-icons.confirmation_num:before {
  content: "\e638";
}
.material-icons.confirmation_number:before {
  content: "\e638";
}
.material-icons.connect_without_contact:before {
  content: "\f223";
}
.material-icons.connected_tv:before {
  content: "\e998";
}
.material-icons.connecting_airports:before {
  content: "\e7c9";
}
.material-icons.construction:before {
  content: "\ea3c";
}
.material-icons.contact_mail:before {
  content: "\e0d0";
}
.material-icons.contact_page:before {
  content: "\f22e";
}
.material-icons.contact_phone:before {
  content: "\e0cf";
}
.material-icons.contact_support:before {
  content: "\e94c";
}
.material-icons.contactless:before {
  content: "\ea71";
}
.material-icons.contacts:before {
  content: "\e0ba";
}
.material-icons.content_copy:before {
  content: "\e14d";
}
.material-icons.content_cut:before {
  content: "\e14e";
}
.material-icons.content_paste:before {
  content: "\e14f";
}
.material-icons.content_paste_go:before {
  content: "\ea8e";
}
.material-icons.content_paste_off:before {
  content: "\e4f8";
}
.material-icons.content_paste_search:before {
  content: "\ea9b";
}
.material-icons.contrast:before {
  content: "\eb37";
}
.material-icons.control_camera:before {
  content: "\e074";
}
.material-icons.control_point:before {
  content: "\e3ba";
}
.material-icons.control_point_duplicate:before {
  content: "\e3bb";
}
.material-icons.cookie:before {
  content: "\eaac";
}
.material-icons.copy_all:before {
  content: "\e2ec";
}
.material-icons.copyright:before {
  content: "\e90c";
}
.material-icons.coronavirus:before {
  content: "\f221";
}
.material-icons.corporate_fare:before {
  content: "\f1d0";
}
.material-icons.cottage:before {
  content: "\e587";
}
.material-icons.countertops:before {
  content: "\f1f7";
}
.material-icons.create:before {
  content: "\e150";
}
.material-icons.create_new_folder:before {
  content: "\e2cc";
}
.material-icons.credit_card:before {
  content: "\e870";
}
.material-icons.credit_card_off:before {
  content: "\e4f4";
}
.material-icons.credit_score:before {
  content: "\eff1";
}
.material-icons.crib:before {
  content: "\e588";
}
.material-icons.crisis_alert:before {
  content: "\ebe9";
}
.material-icons.crop:before {
  content: "\e3be";
}
.material-icons.crop_16_9:before {
  content: "\e3bc";
}
.material-icons.crop_3_2:before {
  content: "\e3bd";
}
.material-icons.crop_5_4:before {
  content: "\e3bf";
}
.material-icons.crop_7_5:before {
  content: "\e3c0";
}
.material-icons.crop_din:before {
  content: "\e3c1";
}
.material-icons.crop_free:before {
  content: "\e3c2";
}
.material-icons.crop_landscape:before {
  content: "\e3c3";
}
.material-icons.crop_original:before {
  content: "\e3c4";
}
.material-icons.crop_portrait:before {
  content: "\e3c5";
}
.material-icons.crop_rotate:before {
  content: "\e437";
}
.material-icons.crop_square:before {
  content: "\e3c6";
}
.material-icons.cruelty_free:before {
  content: "\e799";
}
.material-icons.css:before {
  content: "\eb93";
}
.material-icons.currency_bitcoin:before {
  content: "\ebc5";
}
.material-icons.currency_exchange:before {
  content: "\eb70";
}
.material-icons.currency_franc:before {
  content: "\eafa";
}
.material-icons.currency_lira:before {
  content: "\eaef";
}
.material-icons.currency_pound:before {
  content: "\eaf1";
}
.material-icons.currency_ruble:before {
  content: "\eaec";
}
.material-icons.currency_rupee:before {
  content: "\eaf7";
}
.material-icons.currency_yen:before {
  content: "\eafb";
}
.material-icons.currency_yuan:before {
  content: "\eaf9";
}
.material-icons.curtains:before {
  content: "\ec1e";
}
.material-icons.curtains_closed:before {
  content: "\ec1d";
}
.material-icons.cyclone:before {
  content: "\ebd5";
}
.material-icons.dangerous:before {
  content: "\e99a";
}
.material-icons.dark_mode:before {
  content: "\e51c";
}
.material-icons.dashboard:before {
  content: "\e871";
}
.material-icons.dashboard_customize:before {
  content: "\e99b";
}
.material-icons.data_array:before {
  content: "\ead1";
}
.material-icons.data_exploration:before {
  content: "\e76f";
}
.material-icons.data_object:before {
  content: "\ead3";
}
.material-icons.data_saver_off:before {
  content: "\eff2";
}
.material-icons.data_saver_on:before {
  content: "\eff3";
}
.material-icons.data_thresholding:before {
  content: "\eb9f";
}
.material-icons.data_usage:before {
  content: "\e1af";
}
.material-icons.dataset:before {
  content: "\f8ee";
}
.material-icons.dataset_linked:before {
  content: "\f8ef";
}
.material-icons.date_range:before {
  content: "\e916";
}
.material-icons.deblur:before {
  content: "\eb77";
}
.material-icons.deck:before {
  content: "\ea42";
}
.material-icons.dehaze:before {
  content: "\e3c7";
}
.material-icons.delete:before {
  content: "\e872";
}
.material-icons.delete_forever:before {
  content: "\e92b";
}
.material-icons.delete_outline:before {
  content: "\e92e";
}
.material-icons.delete_sweep:before {
  content: "\e16c";
}
.material-icons.delivery_dining:before {
  content: "\ea72";
}
.material-icons.density_large:before {
  content: "\eba9";
}
.material-icons.density_medium:before {
  content: "\eb9e";
}
.material-icons.density_small:before {
  content: "\eba8";
}
.material-icons.departure_board:before {
  content: "\e576";
}
.material-icons.description:before {
  content: "\e873";
}
.material-icons.deselect:before {
  content: "\ebb6";
}
.material-icons.design_services:before {
  content: "\f10a";
}
.material-icons.desk:before {
  content: "\f8f4";
}
.material-icons.desktop_access_disabled:before {
  content: "\e99d";
}
.material-icons.desktop_mac:before {
  content: "\e30b";
}
.material-icons.desktop_windows:before {
  content: "\e30c";
}
.material-icons.details:before {
  content: "\e3c8";
}
.material-icons.developer_board:before {
  content: "\e30d";
}
.material-icons.developer_board_off:before {
  content: "\e4ff";
}
.material-icons.developer_mode:before {
  content: "\e1b0";
}
.material-icons.device_hub:before {
  content: "\e335";
}
.material-icons.device_thermostat:before {
  content: "\e1ff";
}
.material-icons.device_unknown:before {
  content: "\e339";
}
.material-icons.devices:before {
  content: "\e1b1";
}
.material-icons.devices_fold:before {
  content: "\ebde";
}
.material-icons.devices_other:before {
  content: "\e337";
}
.material-icons.dialer_sip:before {
  content: "\e0bb";
}
.material-icons.dialpad:before {
  content: "\e0bc";
}
.material-icons.diamond:before {
  content: "\ead5";
}
.material-icons.difference:before {
  content: "\eb7d";
}
.material-icons.dining:before {
  content: "\eff4";
}
.material-icons.dinner_dining:before {
  content: "\ea57";
}
.material-icons.directions:before {
  content: "\e52e";
}
.material-icons.directions_bike:before {
  content: "\e52f";
}
.material-icons.directions_boat:before {
  content: "\e532";
}
.material-icons.directions_boat_filled:before {
  content: "\eff5";
}
.material-icons.directions_bus:before {
  content: "\e530";
}
.material-icons.directions_bus_filled:before {
  content: "\eff6";
}
.material-icons.directions_car:before {
  content: "\e531";
}
.material-icons.directions_car_filled:before {
  content: "\eff7";
}
.material-icons.directions_ferry:before {
  content: "\e532";
}
.material-icons.directions_off:before {
  content: "\f10f";
}
.material-icons.directions_railway:before {
  content: "\e534";
}
.material-icons.directions_railway_filled:before {
  content: "\eff8";
}
.material-icons.directions_run:before {
  content: "\e566";
}
.material-icons.directions_subway:before {
  content: "\e533";
}
.material-icons.directions_subway_filled:before {
  content: "\eff9";
}
.material-icons.directions_train:before {
  content: "\e534";
}
.material-icons.directions_transit:before {
  content: "\e535";
}
.material-icons.directions_transit_filled:before {
  content: "\effa";
}
.material-icons.directions_walk:before {
  content: "\e536";
}
.material-icons.dirty_lens:before {
  content: "\ef4b";
}
.material-icons.disabled_by_default:before {
  content: "\f230";
}
.material-icons.disabled_visible:before {
  content: "\e76e";
}
.material-icons.disc_full:before {
  content: "\e610";
}
.material-icons.discord:before {
  content: "\ea6c";
}
.material-icons.discount:before {
  content: "\ebc9";
}
.material-icons.display_settings:before {
  content: "\eb97";
}
.material-icons.dnd_forwardslash:before {
  content: "\e611";
}
.material-icons.dns:before {
  content: "\e875";
}
.material-icons.do_disturb:before {
  content: "\f08c";
}
.material-icons.do_disturb_alt:before {
  content: "\f08d";
}
.material-icons.do_disturb_off:before {
  content: "\f08e";
}
.material-icons.do_disturb_on:before {
  content: "\f08f";
}
.material-icons.do_not_disturb:before {
  content: "\e612";
}
.material-icons.do_not_disturb_alt:before {
  content: "\e611";
}
.material-icons.do_not_disturb_off:before {
  content: "\e643";
}
.material-icons.do_not_disturb_on:before {
  content: "\e644";
}
.material-icons.do_not_disturb_on_total_silence:before {
  content: "\effb";
}
.material-icons.do_not_step:before {
  content: "\f19f";
}
.material-icons.do_not_touch:before {
  content: "\f1b0";
}
.material-icons.dock:before {
  content: "\e30e";
}
.material-icons.document_scanner:before {
  content: "\e5fa";
}
.material-icons.domain:before {
  content: "\e7ee";
}
.material-icons.domain_add:before {
  content: "\eb62";
}
.material-icons.domain_disabled:before {
  content: "\e0ef";
}
.material-icons.domain_verification:before {
  content: "\ef4c";
}
.material-icons.done:before {
  content: "\e876";
}
.material-icons.done_all:before {
  content: "\e877";
}
.material-icons.done_outline:before {
  content: "\e92f";
}
.material-icons.donut_large:before {
  content: "\e917";
}
.material-icons.donut_small:before {
  content: "\e918";
}
.material-icons.door_back:before {
  content: "\effc";
}
.material-icons.door_front:before {
  content: "\effd";
}
.material-icons.door_sliding:before {
  content: "\effe";
}
.material-icons.doorbell:before {
  content: "\efff";
}
.material-icons.double_arrow:before {
  content: "\ea50";
}
.material-icons.downhill_skiing:before {
  content: "\e509";
}
.material-icons.download:before {
  content: "\f090";
}
.material-icons.download_done:before {
  content: "\f091";
}
.material-icons.download_for_offline:before {
  content: "\f000";
}
.material-icons.downloading:before {
  content: "\f001";
}
.material-icons.drafts:before {
  content: "\e151";
}
.material-icons.drag_handle:before {
  content: "\e25d";
}
.material-icons.drag_indicator:before {
  content: "\e945";
}
.material-icons.draw:before {
  content: "\e746";
}
.material-icons.drive_eta:before {
  content: "\e613";
}
.material-icons.drive_file_move:before {
  content: "\e675";
}
.material-icons.drive_file_move_outline:before {
  content: "\e9a1";
}
.material-icons.drive_file_move_rtl:before {
  content: "\e76d";
}
.material-icons.drive_file_rename_outline:before {
  content: "\e9a2";
}
.material-icons.drive_folder_upload:before {
  content: "\e9a3";
}
.material-icons.dry:before {
  content: "\f1b3";
}
.material-icons.dry_cleaning:before {
  content: "\ea58";
}
.material-icons.duo:before {
  content: "\e9a5";
}
.material-icons.dvr:before {
  content: "\e1b2";
}
.material-icons.dynamic_feed:before {
  content: "\ea14";
}
.material-icons.dynamic_form:before {
  content: "\f1bf";
}
.material-icons.e_mobiledata:before {
  content: "\f002";
}
.material-icons.earbuds:before {
  content: "\f003";
}
.material-icons.earbuds_battery:before {
  content: "\f004";
}
.material-icons.east:before {
  content: "\f1df";
}
.material-icons.eco:before {
  content: "\ea35";
}
.material-icons.edgesensor_high:before {
  content: "\f005";
}
.material-icons.edgesensor_low:before {
  content: "\f006";
}
.material-icons.edit:before {
  content: "\e3c9";
}
.material-icons.edit_attributes:before {
  content: "\e578";
}
.material-icons.edit_calendar:before {
  content: "\e742";
}
.material-icons.edit_location:before {
  content: "\e568";
}
.material-icons.edit_location_alt:before {
  content: "\e1c5";
}
.material-icons.edit_note:before {
  content: "\e745";
}
.material-icons.edit_notifications:before {
  content: "\e525";
}
.material-icons.edit_off:before {
  content: "\e950";
}
.material-icons.edit_road:before {
  content: "\ef4d";
}
.material-icons.egg:before {
  content: "\eacc";
}
.material-icons.egg_alt:before {
  content: "\eac8";
}
.material-icons.eject:before {
  content: "\e8fb";
}
.material-icons.elderly:before {
  content: "\f21a";
}
.material-icons.elderly_woman:before {
  content: "\eb69";
}
.material-icons.electric_bike:before {
  content: "\eb1b";
}
.material-icons.electric_bolt:before {
  content: "\ec1c";
}
.material-icons.electric_car:before {
  content: "\eb1c";
}
.material-icons.electric_meter:before {
  content: "\ec1b";
}
.material-icons.electric_moped:before {
  content: "\eb1d";
}
.material-icons.electric_rickshaw:before {
  content: "\eb1e";
}
.material-icons.electric_scooter:before {
  content: "\eb1f";
}
.material-icons.electrical_services:before {
  content: "\f102";
}
.material-icons.elevator:before {
  content: "\f1a0";
}
.material-icons.email:before {
  content: "\e0be";
}
.material-icons.emergency:before {
  content: "\e1eb";
}
.material-icons.emergency_recording:before {
  content: "\ebf4";
}
.material-icons.emergency_share:before {
  content: "\ebf6";
}
.material-icons.emoji_emotions:before {
  content: "\ea22";
}
.material-icons.emoji_events:before {
  content: "\ea23";
}
.material-icons.emoji_flags:before {
  content: "\ea1a";
}
.material-icons.emoji_food_beverage:before {
  content: "\ea1b";
}
.material-icons.emoji_nature:before {
  content: "\ea1c";
}
.material-icons.emoji_objects:before {
  content: "\ea24";
}
.material-icons.emoji_people:before {
  content: "\ea1d";
}
.material-icons.emoji_symbols:before {
  content: "\ea1e";
}
.material-icons.emoji_transportation:before {
  content: "\ea1f";
}
.material-icons.energy_savings_leaf:before {
  content: "\ec1a";
}
.material-icons.engineering:before {
  content: "\ea3d";
}
.material-icons.enhance_photo_translate:before {
  content: "\e8fc";
}
.material-icons.enhanced_encryption:before {
  content: "\e63f";
}
.material-icons.equalizer:before {
  content: "\e01d";
}
.material-icons.error:before {
  content: "\e000";
}
.material-icons.error_outline:before {
  content: "\e001";
}
.material-icons.escalator:before {
  content: "\f1a1";
}
.material-icons.escalator_warning:before {
  content: "\f1ac";
}
.material-icons.euro:before {
  content: "\ea15";
}
.material-icons.euro_symbol:before {
  content: "\e926";
}
.material-icons.ev_station:before {
  content: "\e56d";
}
.material-icons.event:before {
  content: "\e878";
}
.material-icons.event_available:before {
  content: "\e614";
}
.material-icons.event_busy:before {
  content: "\e615";
}
.material-icons.event_note:before {
  content: "\e616";
}
.material-icons.event_repeat:before {
  content: "\eb7b";
}
.material-icons.event_seat:before {
  content: "\e903";
}
.material-icons.exit_to_app:before {
  content: "\e879";
}
.material-icons.expand:before {
  content: "\e94f";
}
.material-icons.expand_circle_down:before {
  content: "\e7cd";
}
.material-icons.expand_less:before {
  content: "\e5ce";
}
.material-icons.expand_more:before {
  content: "\e5cf";
}
.material-icons.explicit:before {
  content: "\e01e";
}
.material-icons.explore:before {
  content: "\e87a";
}
.material-icons.explore_off:before {
  content: "\e9a8";
}
.material-icons.exposure:before {
  content: "\e3ca";
}
.material-icons.exposure_minus_1:before {
  content: "\e3cb";
}
.material-icons.exposure_minus_2:before {
  content: "\e3cc";
}
.material-icons.exposure_neg_1:before {
  content: "\e3cb";
}
.material-icons.exposure_neg_2:before {
  content: "\e3cc";
}
.material-icons.exposure_plus_1:before {
  content: "\e3cd";
}
.material-icons.exposure_plus_2:before {
  content: "\e3ce";
}
.material-icons.exposure_zero:before {
  content: "\e3cf";
}
.material-icons.extension:before {
  content: "\e87b";
}
.material-icons.extension_off:before {
  content: "\e4f5";
}
.material-icons.face:before {
  content: "\e87c";
}
.material-icons.face_retouching_natural:before {
  content: "\ef4e";
}
.material-icons.face_retouching_off:before {
  content: "\f007";
}
.material-icons.facebook:before {
  content: "\f234";
}
.material-icons.fact_check:before {
  content: "\f0c5";
}
.material-icons.factory:before {
  content: "\ebbc";
}
.material-icons.family_restroom:before {
  content: "\f1a2";
}
.material-icons.fast_forward:before {
  content: "\e01f";
}
.material-icons.fast_rewind:before {
  content: "\e020";
}
.material-icons.fastfood:before {
  content: "\e57a";
}
.material-icons.favorite:before {
  content: "\e87d";
}
.material-icons.favorite_border:before {
  content: "\e87e";
}
.material-icons.favorite_outline:before {
  content: "\e87e";
}
.material-icons.fax:before {
  content: "\ead8";
}
.material-icons.featured_play_list:before {
  content: "\e06d";
}
.material-icons.featured_video:before {
  content: "\e06e";
}
.material-icons.feed:before {
  content: "\f009";
}
.material-icons.feedback:before {
  content: "\e87f";
}
.material-icons.female:before {
  content: "\e590";
}
.material-icons.fence:before {
  content: "\f1f6";
}
.material-icons.festival:before {
  content: "\ea68";
}
.material-icons.fiber_dvr:before {
  content: "\e05d";
}
.material-icons.fiber_manual_record:before {
  content: "\e061";
}
.material-icons.fiber_new:before {
  content: "\e05e";
}
.material-icons.fiber_pin:before {
  content: "\e06a";
}
.material-icons.fiber_smart_record:before {
  content: "\e062";
}
.material-icons.file_copy:before {
  content: "\e173";
}
.material-icons.file_download:before {
  content: "\e2c4";
}
.material-icons.file_download_done:before {
  content: "\e9aa";
}
.material-icons.file_download_off:before {
  content: "\e4fe";
}
.material-icons.file_open:before {
  content: "\eaf3";
}
.material-icons.file_present:before {
  content: "\ea0e";
}
.material-icons.file_upload:before {
  content: "\e2c6";
}
.material-icons.filter:before {
  content: "\e3d3";
}
.material-icons.filter_1:before {
  content: "\e3d0";
}
.material-icons.filter_2:before {
  content: "\e3d1";
}
.material-icons.filter_3:before {
  content: "\e3d2";
}
.material-icons.filter_4:before {
  content: "\e3d4";
}
.material-icons.filter_5:before {
  content: "\e3d5";
}
.material-icons.filter_6:before {
  content: "\e3d6";
}
.material-icons.filter_7:before {
  content: "\e3d7";
}
.material-icons.filter_8:before {
  content: "\e3d8";
}
.material-icons.filter_9:before {
  content: "\e3d9";
}
.material-icons.filter_9_plus:before {
  content: "\e3da";
}
.material-icons.filter_alt:before {
  content: "\ef4f";
}
.material-icons.filter_alt_off:before {
  content: "\eb32";
}
.material-icons.filter_b_and_w:before {
  content: "\e3db";
}
.material-icons.filter_center_focus:before {
  content: "\e3dc";
}
.material-icons.filter_drama:before {
  content: "\e3dd";
}
.material-icons.filter_frames:before {
  content: "\e3de";
}
.material-icons.filter_hdr:before {
  content: "\e3df";
}
.material-icons.filter_list:before {
  content: "\e152";
}
.material-icons.filter_list_alt:before {
  content: "\e94e";
}
.material-icons.filter_list_off:before {
  content: "\eb57";
}
.material-icons.filter_none:before {
  content: "\e3e0";
}
.material-icons.filter_tilt_shift:before {
  content: "\e3e2";
}
.material-icons.filter_vintage:before {
  content: "\e3e3";
}
.material-icons.find_in_page:before {
  content: "\e880";
}
.material-icons.find_replace:before {
  content: "\e881";
}
.material-icons.fingerprint:before {
  content: "\e90d";
}
.material-icons.fire_extinguisher:before {
  content: "\f1d8";
}
.material-icons.fire_hydrant:before {
  content: "\f1a3";
}
.material-icons.fire_hydrant_alt:before {
  content: "\f8f1";
}
.material-icons.fire_truck:before {
  content: "\f8f2";
}
.material-icons.fireplace:before {
  content: "\ea43";
}
.material-icons.first_page:before {
  content: "\e5dc";
}
.material-icons.fit_screen:before {
  content: "\ea10";
}
.material-icons.fitbit:before {
  content: "\e82b";
}
.material-icons.fitness_center:before {
  content: "\eb43";
}
.material-icons.flag:before {
  content: "\e153";
}
.material-icons.flag_circle:before {
  content: "\eaf8";
}
.material-icons.flaky:before {
  content: "\ef50";
}
.material-icons.flare:before {
  content: "\e3e4";
}
.material-icons.flash_auto:before {
  content: "\e3e5";
}
.material-icons.flash_off:before {
  content: "\e3e6";
}
.material-icons.flash_on:before {
  content: "\e3e7";
}
.material-icons.flashlight_off:before {
  content: "\f00a";
}
.material-icons.flashlight_on:before {
  content: "\f00b";
}
.material-icons.flatware:before {
  content: "\f00c";
}
.material-icons.flight:before {
  content: "\e539";
}
.material-icons.flight_class:before {
  content: "\e7cb";
}
.material-icons.flight_land:before {
  content: "\e904";
}
.material-icons.flight_takeoff:before {
  content: "\e905";
}
.material-icons.flip:before {
  content: "\e3e8";
}
.material-icons.flip_camera_android:before {
  content: "\ea37";
}
.material-icons.flip_camera_ios:before {
  content: "\ea38";
}
.material-icons.flip_to_back:before {
  content: "\e882";
}
.material-icons.flip_to_front:before {
  content: "\e883";
}
.material-icons.flood:before {
  content: "\ebe6";
}
.material-icons.flourescent:before {
  content: "\f00d";
}
.material-icons.flutter_dash:before {
  content: "\e00b";
}
.material-icons.fmd_bad:before {
  content: "\f00e";
}
.material-icons.fmd_good:before {
  content: "\f00f";
}
.material-icons.foggy:before {
  content: "\e818";
}
.material-icons.folder:before {
  content: "\e2c7";
}
.material-icons.folder_copy:before {
  content: "\ebbd";
}
.material-icons.folder_delete:before {
  content: "\eb34";
}
.material-icons.folder_off:before {
  content: "\eb83";
}
.material-icons.folder_open:before {
  content: "\e2c8";
}
.material-icons.folder_shared:before {
  content: "\e2c9";
}
.material-icons.folder_special:before {
  content: "\e617";
}
.material-icons.folder_zip:before {
  content: "\eb2c";
}
.material-icons.follow_the_signs:before {
  content: "\f222";
}
.material-icons.font_download:before {
  content: "\e167";
}
.material-icons.font_download_off:before {
  content: "\e4f9";
}
.material-icons.food_bank:before {
  content: "\f1f2";
}
.material-icons.forest:before {
  content: "\ea99";
}
.material-icons.fork_left:before {
  content: "\eba0";
}
.material-icons.fork_right:before {
  content: "\ebac";
}
.material-icons.format_align_center:before {
  content: "\e234";
}
.material-icons.format_align_justify:before {
  content: "\e235";
}
.material-icons.format_align_left:before {
  content: "\e236";
}
.material-icons.format_align_right:before {
  content: "\e237";
}
.material-icons.format_bold:before {
  content: "\e238";
}
.material-icons.format_clear:before {
  content: "\e239";
}
.material-icons.format_color_fill:before {
  content: "\e23a";
}
.material-icons.format_color_reset:before {
  content: "\e23b";
}
.material-icons.format_color_text:before {
  content: "\e23c";
}
.material-icons.format_indent_decrease:before {
  content: "\e23d";
}
.material-icons.format_indent_increase:before {
  content: "\e23e";
}
.material-icons.format_italic:before {
  content: "\e23f";
}
.material-icons.format_line_spacing:before {
  content: "\e240";
}
.material-icons.format_list_bulleted:before {
  content: "\e241";
}
.material-icons.format_list_numbered:before {
  content: "\e242";
}
.material-icons.format_list_numbered_rtl:before {
  content: "\e267";
}
.material-icons.format_overline:before {
  content: "\eb65";
}
.material-icons.format_paint:before {
  content: "\e243";
}
.material-icons.format_quote:before {
  content: "\e244";
}
.material-icons.format_shapes:before {
  content: "\e25e";
}
.material-icons.format_size:before {
  content: "\e245";
}
.material-icons.format_strikethrough:before {
  content: "\e246";
}
.material-icons.format_textdirection_l_to_r:before {
  content: "\e247";
}
.material-icons.format_textdirection_r_to_l:before {
  content: "\e248";
}
.material-icons.format_underline:before {
  content: "\e249";
}
.material-icons.format_underlined:before {
  content: "\e249";
}
.material-icons.fort:before {
  content: "\eaad";
}
.material-icons.forum:before {
  content: "\e0bf";
}
.material-icons.forward:before {
  content: "\e154";
}
.material-icons.forward_10:before {
  content: "\e056";
}
.material-icons.forward_30:before {
  content: "\e057";
}
.material-icons.forward_5:before {
  content: "\e058";
}
.material-icons.forward_to_inbox:before {
  content: "\f187";
}
.material-icons.foundation:before {
  content: "\f200";
}
.material-icons.free_breakfast:before {
  content: "\eb44";
}
.material-icons.free_cancellation:before {
  content: "\e748";
}
.material-icons.front_hand:before {
  content: "\e769";
}
.material-icons.fullscreen:before {
  content: "\e5d0";
}
.material-icons.fullscreen_exit:before {
  content: "\e5d1";
}
.material-icons.functions:before {
  content: "\e24a";
}
.material-icons.g_mobiledata:before {
  content: "\f010";
}
.material-icons.g_translate:before {
  content: "\e927";
}
.material-icons.gamepad:before {
  content: "\e30f";
}
.material-icons.games:before {
  content: "\e021";
}
.material-icons.garage:before {
  content: "\f011";
}
.material-icons.gas_meter:before {
  content: "\ec19";
}
.material-icons.gavel:before {
  content: "\e90e";
}
.material-icons.generating_tokens:before {
  content: "\e749";
}
.material-icons.gesture:before {
  content: "\e155";
}
.material-icons.get_app:before {
  content: "\e884";
}
.material-icons.gif:before {
  content: "\e908";
}
.material-icons.gif_box:before {
  content: "\e7a3";
}
.material-icons.girl:before {
  content: "\eb68";
}
.material-icons.gite:before {
  content: "\e58b";
}
.material-icons.goat:before {
  content: "\ebff";
}
.material-icons.golf_course:before {
  content: "\eb45";
}
.material-icons.gpp_bad:before {
  content: "\f012";
}
.material-icons.gpp_good:before {
  content: "\f013";
}
.material-icons.gpp_maybe:before {
  content: "\f014";
}
.material-icons.gps_fixed:before {
  content: "\e1b3";
}
.material-icons.gps_not_fixed:before {
  content: "\e1b4";
}
.material-icons.gps_off:before {
  content: "\e1b5";
}
.material-icons.grade:before {
  content: "\e885";
}
.material-icons.gradient:before {
  content: "\e3e9";
}
.material-icons.grading:before {
  content: "\ea4f";
}
.material-icons.grain:before {
  content: "\e3ea";
}
.material-icons.graphic_eq:before {
  content: "\e1b8";
}
.material-icons.grass:before {
  content: "\f205";
}
.material-icons.grid_3x3:before {
  content: "\f015";
}
.material-icons.grid_4x4:before {
  content: "\f016";
}
.material-icons.grid_goldenratio:before {
  content: "\f017";
}
.material-icons.grid_off:before {
  content: "\e3eb";
}
.material-icons.grid_on:before {
  content: "\e3ec";
}
.material-icons.grid_view:before {
  content: "\e9b0";
}
.material-icons.group:before {
  content: "\e7ef";
}
.material-icons.group_add:before {
  content: "\e7f0";
}
.material-icons.group_off:before {
  content: "\e747";
}
.material-icons.group_remove:before {
  content: "\e7ad";
}
.material-icons.group_work:before {
  content: "\e886";
}
.material-icons.groups:before {
  content: "\f233";
}
.material-icons.h_mobiledata:before {
  content: "\f018";
}
.material-icons.h_plus_mobiledata:before {
  content: "\f019";
}
.material-icons.hail:before {
  content: "\e9b1";
}
.material-icons.handshake:before {
  content: "\ebcb";
}
.material-icons.handyman:before {
  content: "\f10b";
}
.material-icons.hardware:before {
  content: "\ea59";
}
.material-icons.hd:before {
  content: "\e052";
}
.material-icons.hdr_auto:before {
  content: "\f01a";
}
.material-icons.hdr_auto_select:before {
  content: "\f01b";
}
.material-icons.hdr_enhanced_select:before {
  content: "\ef51";
}
.material-icons.hdr_off:before {
  content: "\e3ed";
}
.material-icons.hdr_off_select:before {
  content: "\f01c";
}
.material-icons.hdr_on:before {
  content: "\e3ee";
}
.material-icons.hdr_on_select:before {
  content: "\f01d";
}
.material-icons.hdr_plus:before {
  content: "\f01e";
}
.material-icons.hdr_strong:before {
  content: "\e3f1";
}
.material-icons.hdr_weak:before {
  content: "\e3f2";
}
.material-icons.headphones:before {
  content: "\f01f";
}
.material-icons.headphones_battery:before {
  content: "\f020";
}
.material-icons.headset:before {
  content: "\e310";
}
.material-icons.headset_mic:before {
  content: "\e311";
}
.material-icons.headset_off:before {
  content: "\e33a";
}
.material-icons.healing:before {
  content: "\e3f3";
}
.material-icons.health_and_safety:before {
  content: "\e1d5";
}
.material-icons.hearing:before {
  content: "\e023";
}
.material-icons.hearing_disabled:before {
  content: "\f104";
}
.material-icons.heart_broken:before {
  content: "\eac2";
}
.material-icons.heat_pump:before {
  content: "\ec18";
}
.material-icons.height:before {
  content: "\ea16";
}
.material-icons.help:before {
  content: "\e887";
}
.material-icons.help_center:before {
  content: "\f1c0";
}
.material-icons.help_outline:before {
  content: "\e8fd";
}
.material-icons.hevc:before {
  content: "\f021";
}
.material-icons.hexagon:before {
  content: "\eb39";
}
.material-icons.hide_image:before {
  content: "\f022";
}
.material-icons.hide_source:before {
  content: "\f023";
}
.material-icons.high_quality:before {
  content: "\e024";
}
.material-icons.highlight:before {
  content: "\e25f";
}
.material-icons.highlight_alt:before {
  content: "\ef52";
}
.material-icons.highlight_off:before {
  content: "\e888";
}
.material-icons.highlight_remove:before {
  content: "\e888";
}
.material-icons.hiking:before {
  content: "\e50a";
}
.material-icons.history:before {
  content: "\e889";
}
.material-icons.history_edu:before {
  content: "\ea3e";
}
.material-icons.history_toggle_off:before {
  content: "\f17d";
}
.material-icons.hive:before {
  content: "\eaa6";
}
.material-icons.hls:before {
  content: "\eb8a";
}
.material-icons.hls_off:before {
  content: "\eb8c";
}
.material-icons.holiday_village:before {
  content: "\e58a";
}
.material-icons.home:before {
  content: "\e88a";
}
.material-icons.home_filled:before {
  content: "\e9b2";
}
.material-icons.home_max:before {
  content: "\f024";
}
.material-icons.home_mini:before {
  content: "\f025";
}
.material-icons.home_repair_service:before {
  content: "\f100";
}
.material-icons.home_work:before {
  content: "\ea09";
}
.material-icons.horizontal_distribute:before {
  content: "\e014";
}
.material-icons.horizontal_rule:before {
  content: "\f108";
}
.material-icons.horizontal_split:before {
  content: "\e947";
}
.material-icons.hot_tub:before {
  content: "\eb46";
}
.material-icons.hotel:before {
  content: "\e53a";
}
.material-icons.hotel_class:before {
  content: "\e743";
}
.material-icons.hourglass_bottom:before {
  content: "\ea5c";
}
.material-icons.hourglass_disabled:before {
  content: "\ef53";
}
.material-icons.hourglass_empty:before {
  content: "\e88b";
}
.material-icons.hourglass_full:before {
  content: "\e88c";
}
.material-icons.hourglass_top:before {
  content: "\ea5b";
}
.material-icons.house:before {
  content: "\ea44";
}
.material-icons.house_siding:before {
  content: "\f202";
}
.material-icons.houseboat:before {
  content: "\e584";
}
.material-icons.how_to_reg:before {
  content: "\e174";
}
.material-icons.how_to_vote:before {
  content: "\e175";
}
.material-icons.html:before {
  content: "\eb7e";
}
.material-icons.http:before {
  content: "\e902";
}
.material-icons.https:before {
  content: "\e88d";
}
.material-icons.hub:before {
  content: "\e9f4";
}
.material-icons.hvac:before {
  content: "\f10e";
}
.material-icons.ice_skating:before {
  content: "\e50b";
}
.material-icons.icecream:before {
  content: "\ea69";
}
.material-icons.image:before {
  content: "\e3f4";
}
.material-icons.image_aspect_ratio:before {
  content: "\e3f5";
}
.material-icons.image_not_supported:before {
  content: "\f116";
}
.material-icons.image_search:before {
  content: "\e43f";
}
.material-icons.imagesearch_roller:before {
  content: "\e9b4";
}
.material-icons.import_contacts:before {
  content: "\e0e0";
}
.material-icons.import_export:before {
  content: "\e0c3";
}
.material-icons.important_devices:before {
  content: "\e912";
}
.material-icons.inbox:before {
  content: "\e156";
}
.material-icons.incomplete_circle:before {
  content: "\e79b";
}
.material-icons.indeterminate_check_box:before {
  content: "\e909";
}
.material-icons.info:before {
  content: "\e88e";
}
.material-icons.info_outline:before {
  content: "\e88f";
}
.material-icons.input:before {
  content: "\e890";
}
.material-icons.insert_chart:before {
  content: "\e24b";
}
.material-icons.insert_chart_outlined:before {
  content: "\e26a";
}
.material-icons.insert_comment:before {
  content: "\e24c";
}
.material-icons.insert_drive_file:before {
  content: "\e24d";
}
.material-icons.insert_emoticon:before {
  content: "\e24e";
}
.material-icons.insert_invitation:before {
  content: "\e24f";
}
.material-icons.insert_link:before {
  content: "\e250";
}
.material-icons.insert_page_break:before {
  content: "\eaca";
}
.material-icons.insert_photo:before {
  content: "\e251";
}
.material-icons.insights:before {
  content: "\f092";
}
.material-icons.install_desktop:before {
  content: "\eb71";
}
.material-icons.install_mobile:before {
  content: "\eb72";
}
.material-icons.integration_instructions:before {
  content: "\ef54";
}
.material-icons.interests:before {
  content: "\e7c8";
}
.material-icons.interpreter_mode:before {
  content: "\e83b";
}
.material-icons.inventory:before {
  content: "\e179";
}
.material-icons.inventory_2:before {
  content: "\e1a1";
}
.material-icons.invert_colors:before {
  content: "\e891";
}
.material-icons.invert_colors_off:before {
  content: "\e0c4";
}
.material-icons.invert_colors_on:before {
  content: "\e891";
}
.material-icons.ios_share:before {
  content: "\e6b8";
}
.material-icons.iron:before {
  content: "\e583";
}
.material-icons.iso:before {
  content: "\e3f6";
}
.material-icons.javascript:before {
  content: "\eb7c";
}
.material-icons.join_full:before {
  content: "\eaeb";
}
.material-icons.join_inner:before {
  content: "\eaf4";
}
.material-icons.join_left:before {
  content: "\eaf2";
}
.material-icons.join_right:before {
  content: "\eaea";
}
.material-icons.kayaking:before {
  content: "\e50c";
}
.material-icons.kebab_dining:before {
  content: "\e842";
}
.material-icons.key:before {
  content: "\e73c";
}
.material-icons.key_off:before {
  content: "\eb84";
}
.material-icons.keyboard:before {
  content: "\e312";
}
.material-icons.keyboard_alt:before {
  content: "\f028";
}
.material-icons.keyboard_arrow_down:before {
  content: "\e313";
}
.material-icons.keyboard_arrow_left:before {
  content: "\e314";
}
.material-icons.keyboard_arrow_right:before {
  content: "\e315";
}
.material-icons.keyboard_arrow_up:before {
  content: "\e316";
}
.material-icons.keyboard_backspace:before {
  content: "\e317";
}
.material-icons.keyboard_capslock:before {
  content: "\e318";
}
.material-icons.keyboard_command:before {
  content: "\eae0";
}
.material-icons.keyboard_command_key:before {
  content: "\eae7";
}
.material-icons.keyboard_control:before {
  content: "\e5d3";
}
.material-icons.keyboard_control_key:before {
  content: "\eae6";
}
.material-icons.keyboard_double_arrow_down:before {
  content: "\ead0";
}
.material-icons.keyboard_double_arrow_left:before {
  content: "\eac3";
}
.material-icons.keyboard_double_arrow_right:before {
  content: "\eac9";
}
.material-icons.keyboard_double_arrow_up:before {
  content: "\eacf";
}
.material-icons.keyboard_hide:before {
  content: "\e31a";
}
.material-icons.keyboard_option:before {
  content: "\eadf";
}
.material-icons.keyboard_option_key:before {
  content: "\eae8";
}
.material-icons.keyboard_return:before {
  content: "\e31b";
}
.material-icons.keyboard_tab:before {
  content: "\e31c";
}
.material-icons.keyboard_voice:before {
  content: "\e31d";
}
.material-icons.king_bed:before {
  content: "\ea45";
}
.material-icons.kitchen:before {
  content: "\eb47";
}
.material-icons.kitesurfing:before {
  content: "\e50d";
}
.material-icons.label:before {
  content: "\e892";
}
.material-icons.label_important:before {
  content: "\e937";
}
.material-icons.label_important_outline:before {
  content: "\e948";
}
.material-icons.label_off:before {
  content: "\e9b6";
}
.material-icons.label_outline:before {
  content: "\e893";
}
.material-icons.lan:before {
  content: "\eb2f";
}
.material-icons.landscape:before {
  content: "\e3f7";
}
.material-icons.landslide:before {
  content: "\ebd7";
}
.material-icons.language:before {
  content: "\e894";
}
.material-icons.laptop:before {
  content: "\e31e";
}
.material-icons.laptop_chromebook:before {
  content: "\e31f";
}
.material-icons.laptop_mac:before {
  content: "\e320";
}
.material-icons.laptop_windows:before {
  content: "\e321";
}
.material-icons.last_page:before {
  content: "\e5dd";
}
.material-icons.launch:before {
  content: "\e895";
}
.material-icons.layers:before {
  content: "\e53b";
}
.material-icons.layers_clear:before {
  content: "\e53c";
}
.material-icons.leaderboard:before {
  content: "\f20c";
}
.material-icons.leak_add:before {
  content: "\e3f8";
}
.material-icons.leak_remove:before {
  content: "\e3f9";
}
.material-icons.leave_bags_at_home:before {
  content: "\f21b";
}
.material-icons.legend_toggle:before {
  content: "\f11b";
}
.material-icons.lens:before {
  content: "\e3fa";
}
.material-icons.lens_blur:before {
  content: "\f029";
}
.material-icons.library_add:before {
  content: "\e02e";
}
.material-icons.library_add_check:before {
  content: "\e9b7";
}
.material-icons.library_books:before {
  content: "\e02f";
}
.material-icons.library_music:before {
  content: "\e030";
}
.material-icons.light:before {
  content: "\f02a";
}
.material-icons.light_mode:before {
  content: "\e518";
}
.material-icons.lightbulb:before {
  content: "\e0f0";
}
.material-icons.lightbulb_circle:before {
  content: "\ebfe";
}
.material-icons.lightbulb_outline:before {
  content: "\e90f";
}
.material-icons.line_axis:before {
  content: "\ea9a";
}
.material-icons.line_style:before {
  content: "\e919";
}
.material-icons.line_weight:before {
  content: "\e91a";
}
.material-icons.linear_scale:before {
  content: "\e260";
}
.material-icons.link:before {
  content: "\e157";
}
.material-icons.link_off:before {
  content: "\e16f";
}
.material-icons.linked_camera:before {
  content: "\e438";
}
.material-icons.liquor:before {
  content: "\ea60";
}
.material-icons.list:before {
  content: "\e896";
}
.material-icons.list_alt:before {
  content: "\e0ee";
}
.material-icons.live_help:before {
  content: "\e0c6";
}
.material-icons.live_tv:before {
  content: "\e639";
}
.material-icons.living:before {
  content: "\f02b";
}
.material-icons.local_activity:before {
  content: "\e53f";
}
.material-icons.local_airport:before {
  content: "\e53d";
}
.material-icons.local_atm:before {
  content: "\e53e";
}
.material-icons.local_attraction:before {
  content: "\e53f";
}
.material-icons.local_bar:before {
  content: "\e540";
}
.material-icons.local_cafe:before {
  content: "\e541";
}
.material-icons.local_car_wash:before {
  content: "\e542";
}
.material-icons.local_convenience_store:before {
  content: "\e543";
}
.material-icons.local_dining:before {
  content: "\e556";
}
.material-icons.local_drink:before {
  content: "\e544";
}
.material-icons.local_fire_department:before {
  content: "\ef55";
}
.material-icons.local_florist:before {
  content: "\e545";
}
.material-icons.local_gas_station:before {
  content: "\e546";
}
.material-icons.local_grocery_store:before {
  content: "\e547";
}
.material-icons.local_hospital:before {
  content: "\e548";
}
.material-icons.local_hotel:before {
  content: "\e549";
}
.material-icons.local_laundry_service:before {
  content: "\e54a";
}
.material-icons.local_library:before {
  content: "\e54b";
}
.material-icons.local_mall:before {
  content: "\e54c";
}
.material-icons.local_movies:before {
  content: "\e54d";
}
.material-icons.local_offer:before {
  content: "\e54e";
}
.material-icons.local_parking:before {
  content: "\e54f";
}
.material-icons.local_pharmacy:before {
  content: "\e550";
}
.material-icons.local_phone:before {
  content: "\e551";
}
.material-icons.local_pizza:before {
  content: "\e552";
}
.material-icons.local_play:before {
  content: "\e553";
}
.material-icons.local_police:before {
  content: "\ef56";
}
.material-icons.local_post_office:before {
  content: "\e554";
}
.material-icons.local_print_shop:before {
  content: "\e555";
}
.material-icons.local_printshop:before {
  content: "\e555";
}
.material-icons.local_restaurant:before {
  content: "\e556";
}
.material-icons.local_see:before {
  content: "\e557";
}
.material-icons.local_shipping:before {
  content: "\e558";
}
.material-icons.local_taxi:before {
  content: "\e559";
}
.material-icons.location_city:before {
  content: "\e7f1";
}
.material-icons.location_disabled:before {
  content: "\e1b6";
}
.material-icons.location_history:before {
  content: "\e55a";
}
.material-icons.location_off:before {
  content: "\e0c7";
}
.material-icons.location_on:before {
  content: "\e0c8";
}
.material-icons.location_pin:before {
  content: "\f1db";
}
.material-icons.location_searching:before {
  content: "\e1b7";
}
.material-icons.lock:before {
  content: "\e897";
}
.material-icons.lock_clock:before {
  content: "\ef57";
}
.material-icons.lock_open:before {
  content: "\e898";
}
.material-icons.lock_outline:before {
  content: "\e899";
}
.material-icons.lock_person:before {
  content: "\f8f3";
}
.material-icons.lock_reset:before {
  content: "\eade";
}
.material-icons.login:before {
  content: "\ea77";
}
.material-icons.logo_dev:before {
  content: "\ead6";
}
.material-icons.logout:before {
  content: "\e9ba";
}
.material-icons.looks:before {
  content: "\e3fc";
}
.material-icons.looks_3:before {
  content: "\e3fb";
}
.material-icons.looks_4:before {
  content: "\e3fd";
}
.material-icons.looks_5:before {
  content: "\e3fe";
}
.material-icons.looks_6:before {
  content: "\e3ff";
}
.material-icons.looks_one:before {
  content: "\e400";
}
.material-icons.looks_two:before {
  content: "\e401";
}
.material-icons.loop:before {
  content: "\e028";
}
.material-icons.loupe:before {
  content: "\e402";
}
.material-icons.low_priority:before {
  content: "\e16d";
}
.material-icons.loyalty:before {
  content: "\e89a";
}
.material-icons.lte_mobiledata:before {
  content: "\f02c";
}
.material-icons.lte_plus_mobiledata:before {
  content: "\f02d";
}
.material-icons.luggage:before {
  content: "\f235";
}
.material-icons.lunch_dining:before {
  content: "\ea61";
}
.material-icons.lyrics:before {
  content: "\ec0b";
}
.material-icons.mail:before {
  content: "\e158";
}
.material-icons.mail_lock:before {
  content: "\ec0a";
}
.material-icons.mail_outline:before {
  content: "\e0e1";
}
.material-icons.male:before {
  content: "\e58e";
}
.material-icons.man:before {
  content: "\e4eb";
}
.material-icons.manage_accounts:before {
  content: "\f02e";
}
.material-icons.manage_history:before {
  content: "\ebe7";
}
.material-icons.manage_search:before {
  content: "\f02f";
}
.material-icons.map:before {
  content: "\e55b";
}
.material-icons.maps_home_work:before {
  content: "\f030";
}
.material-icons.maps_ugc:before {
  content: "\ef58";
}
.material-icons.margin:before {
  content: "\e9bb";
}
.material-icons.mark_as_unread:before {
  content: "\e9bc";
}
.material-icons.mark_chat_read:before {
  content: "\f18b";
}
.material-icons.mark_chat_unread:before {
  content: "\f189";
}
.material-icons.mark_email_read:before {
  content: "\f18c";
}
.material-icons.mark_email_unread:before {
  content: "\f18a";
}
.material-icons.mark_unread_chat_alt:before {
  content: "\eb9d";
}
.material-icons.markunread:before {
  content: "\e159";
}
.material-icons.markunread_mailbox:before {
  content: "\e89b";
}
.material-icons.masks:before {
  content: "\f218";
}
.material-icons.maximize:before {
  content: "\e930";
}
.material-icons.media_bluetooth_off:before {
  content: "\f031";
}
.material-icons.media_bluetooth_on:before {
  content: "\f032";
}
.material-icons.mediation:before {
  content: "\efa7";
}
.material-icons.medical_information:before {
  content: "\ebed";
}
.material-icons.medical_services:before {
  content: "\f109";
}
.material-icons.medication:before {
  content: "\f033";
}
.material-icons.medication_liquid:before {
  content: "\ea87";
}
.material-icons.meeting_room:before {
  content: "\eb4f";
}
.material-icons.memory:before {
  content: "\e322";
}
.material-icons.menu:before {
  content: "\e5d2";
}
.material-icons.menu_book:before {
  content: "\ea19";
}
.material-icons.menu_open:before {
  content: "\e9bd";
}
.material-icons.merge:before {
  content: "\eb98";
}
.material-icons.merge_type:before {
  content: "\e252";
}
.material-icons.message:before {
  content: "\e0c9";
}
.material-icons.messenger:before {
  content: "\e0ca";
}
.material-icons.messenger_outline:before {
  content: "\e0cb";
}
.material-icons.mic:before {
  content: "\e029";
}
.material-icons.mic_external_off:before {
  content: "\ef59";
}
.material-icons.mic_external_on:before {
  content: "\ef5a";
}
.material-icons.mic_none:before {
  content: "\e02a";
}
.material-icons.mic_off:before {
  content: "\e02b";
}
.material-icons.microwave:before {
  content: "\f204";
}
.material-icons.military_tech:before {
  content: "\ea3f";
}
.material-icons.minimize:before {
  content: "\e931";
}
.material-icons.minor_crash:before {
  content: "\ebf1";
}
.material-icons.miscellaneous_services:before {
  content: "\f10c";
}
.material-icons.missed_video_call:before {
  content: "\e073";
}
.material-icons.mms:before {
  content: "\e618";
}
.material-icons.mobile_friendly:before {
  content: "\e200";
}
.material-icons.mobile_off:before {
  content: "\e201";
}
.material-icons.mobile_screen_share:before {
  content: "\e0e7";
}
.material-icons.mobiledata_off:before {
  content: "\f034";
}
.material-icons.mode:before {
  content: "\f097";
}
.material-icons.mode_comment:before {
  content: "\e253";
}
.material-icons.mode_edit:before {
  content: "\e254";
}
.material-icons.mode_edit_outline:before {
  content: "\f035";
}
.material-icons.mode_fan_off:before {
  content: "\ec17";
}
.material-icons.mode_night:before {
  content: "\f036";
}
.material-icons.mode_of_travel:before {
  content: "\e7ce";
}
.material-icons.mode_standby:before {
  content: "\f037";
}
.material-icons.model_training:before {
  content: "\f0cf";
}
.material-icons.monetization_on:before {
  content: "\e263";
}
.material-icons.money:before {
  content: "\e57d";
}
.material-icons.money_off:before {
  content: "\e25c";
}
.material-icons.money_off_csred:before {
  content: "\f038";
}
.material-icons.monitor:before {
  content: "\ef5b";
}
.material-icons.monitor_heart:before {
  content: "\eaa2";
}
.material-icons.monitor_weight:before {
  content: "\f039";
}
.material-icons.monochrome_photos:before {
  content: "\e403";
}
.material-icons.mood:before {
  content: "\e7f2";
}
.material-icons.mood_bad:before {
  content: "\e7f3";
}
.material-icons.moped:before {
  content: "\eb28";
}
.material-icons.more:before {
  content: "\e619";
}
.material-icons.more_horiz:before {
  content: "\e5d3";
}
.material-icons.more_time:before {
  content: "\ea5d";
}
.material-icons.more_vert:before {
  content: "\e5d4";
}
.material-icons.mosque:before {
  content: "\eab2";
}
.material-icons.motion_photos_auto:before {
  content: "\f03a";
}
.material-icons.motion_photos_off:before {
  content: "\e9c0";
}
.material-icons.motion_photos_on:before {
  content: "\e9c1";
}
.material-icons.motion_photos_pause:before {
  content: "\f227";
}
.material-icons.motion_photos_paused:before {
  content: "\e9c2";
}
.material-icons.motorcycle:before {
  content: "\e91b";
}
.material-icons.mouse:before {
  content: "\e323";
}
.material-icons.move_down:before {
  content: "\eb61";
}
.material-icons.move_to_inbox:before {
  content: "\e168";
}
.material-icons.move_up:before {
  content: "\eb64";
}
.material-icons.movie:before {
  content: "\e02c";
}
.material-icons.movie_creation:before {
  content: "\e404";
}
.material-icons.movie_filter:before {
  content: "\e43a";
}
.material-icons.moving:before {
  content: "\e501";
}
.material-icons.mp:before {
  content: "\e9c3";
}
.material-icons.multiline_chart:before {
  content: "\e6df";
}
.material-icons.multiple_stop:before {
  content: "\f1b9";
}
.material-icons.multitrack_audio:before {
  content: "\e1b8";
}
.material-icons.museum:before {
  content: "\ea36";
}
.material-icons.music_note:before {
  content: "\e405";
}
.material-icons.music_off:before {
  content: "\e440";
}
.material-icons.music_video:before {
  content: "\e063";
}
.material-icons.my_library_add:before {
  content: "\e02e";
}
.material-icons.my_library_books:before {
  content: "\e02f";
}
.material-icons.my_library_music:before {
  content: "\e030";
}
.material-icons.my_location:before {
  content: "\e55c";
}
.material-icons.nat:before {
  content: "\ef5c";
}
.material-icons.nature:before {
  content: "\e406";
}
.material-icons.nature_people:before {
  content: "\e407";
}
.material-icons.navigate_before:before {
  content: "\e408";
}
.material-icons.navigate_next:before {
  content: "\e409";
}
.material-icons.navigation:before {
  content: "\e55d";
}
.material-icons.near_me:before {
  content: "\e569";
}
.material-icons.near_me_disabled:before {
  content: "\f1ef";
}
.material-icons.nearby_error:before {
  content: "\f03b";
}
.material-icons.nearby_off:before {
  content: "\f03c";
}
.material-icons.nest_cam_wired_stand:before {
  content: "\ec16";
}
.material-icons.network_cell:before {
  content: "\e1b9";
}
.material-icons.network_check:before {
  content: "\e640";
}
.material-icons.network_locked:before {
  content: "\e61a";
}
.material-icons.network_ping:before {
  content: "\ebca";
}
.material-icons.network_wifi:before {
  content: "\e1ba";
}
.material-icons.network_wifi_1_bar:before {
  content: "\ebe4";
}
.material-icons.network_wifi_2_bar:before {
  content: "\ebd6";
}
.material-icons.network_wifi_3_bar:before {
  content: "\ebe1";
}
.material-icons.new_label:before {
  content: "\e609";
}
.material-icons.new_releases:before {
  content: "\e031";
}
.material-icons.newspaper:before {
  content: "\eb81";
}
.material-icons.next_plan:before {
  content: "\ef5d";
}
.material-icons.next_week:before {
  content: "\e16a";
}
.material-icons.nfc:before {
  content: "\e1bb";
}
.material-icons.night_shelter:before {
  content: "\f1f1";
}
.material-icons.nightlife:before {
  content: "\ea62";
}
.material-icons.nightlight:before {
  content: "\f03d";
}
.material-icons.nightlight_round:before {
  content: "\ef5e";
}
.material-icons.nights_stay:before {
  content: "\ea46";
}
.material-icons.no_accounts:before {
  content: "\f03e";
}
.material-icons.no_adult_content:before {
  content: "\f8fe";
}
.material-icons.no_backpack:before {
  content: "\f237";
}
.material-icons.no_cell:before {
  content: "\f1a4";
}
.material-icons.no_crash:before {
  content: "\ebf0";
}
.material-icons.no_drinks:before {
  content: "\f1a5";
}
.material-icons.no_encryption:before {
  content: "\e641";
}
.material-icons.no_encryption_gmailerrorred:before {
  content: "\f03f";
}
.material-icons.no_flash:before {
  content: "\f1a6";
}
.material-icons.no_food:before {
  content: "\f1a7";
}
.material-icons.no_luggage:before {
  content: "\f23b";
}
.material-icons.no_meals:before {
  content: "\f1d6";
}
.material-icons.no_meals_ouline:before {
  content: "\f229";
}
.material-icons.no_meeting_room:before {
  content: "\eb4e";
}
.material-icons.no_photography:before {
  content: "\f1a8";
}
.material-icons.no_sim:before {
  content: "\e0cc";
}
.material-icons.no_stroller:before {
  content: "\f1af";
}
.material-icons.no_transfer:before {
  content: "\f1d5";
}
.material-icons.noise_aware:before {
  content: "\ebec";
}
.material-icons.noise_control_off:before {
  content: "\ebf3";
}
.material-icons.nordic_walking:before {
  content: "\e50e";
}
.material-icons.north:before {
  content: "\f1e0";
}
.material-icons.north_east:before {
  content: "\f1e1";
}
.material-icons.north_west:before {
  content: "\f1e2";
}
.material-icons.not_accessible:before {
  content: "\f0fe";
}
.material-icons.not_interested:before {
  content: "\e033";
}
.material-icons.not_listed_location:before {
  content: "\e575";
}
.material-icons.not_started:before {
  content: "\f0d1";
}
.material-icons.note:before {
  content: "\e06f";
}
.material-icons.note_add:before {
  content: "\e89c";
}
.material-icons.note_alt:before {
  content: "\f040";
}
.material-icons.notes:before {
  content: "\e26c";
}
.material-icons.notification_add:before {
  content: "\e399";
}
.material-icons.notification_important:before {
  content: "\e004";
}
.material-icons.notifications:before {
  content: "\e7f4";
}
.material-icons.notifications_active:before {
  content: "\e7f7";
}
.material-icons.notifications_none:before {
  content: "\e7f5";
}
.material-icons.notifications_off:before {
  content: "\e7f6";
}
.material-icons.notifications_on:before {
  content: "\e7f7";
}
.material-icons.notifications_paused:before {
  content: "\e7f8";
}
.material-icons.now_wallpaper:before {
  content: "\e1bc";
}
.material-icons.now_widgets:before {
  content: "\e1bd";
}
.material-icons.numbers:before {
  content: "\eac7";
}
.material-icons.offline_bolt:before {
  content: "\e932";
}
.material-icons.offline_pin:before {
  content: "\e90a";
}
.material-icons.offline_share:before {
  content: "\e9c5";
}
.material-icons.oil_barrel:before {
  content: "\ec15";
}
.material-icons.on_device_training:before {
  content: "\ebfd";
}
.material-icons.ondemand_video:before {
  content: "\e63a";
}
.material-icons.online_prediction:before {
  content: "\f0eb";
}
.material-icons.opacity:before {
  content: "\e91c";
}
.material-icons.open_in_browser:before {
  content: "\e89d";
}
.material-icons.open_in_full:before {
  content: "\f1ce";
}
.material-icons.open_in_new:before {
  content: "\e89e";
}
.material-icons.open_in_new_off:before {
  content: "\e4f6";
}
.material-icons.open_with:before {
  content: "\e89f";
}
.material-icons.other_houses:before {
  content: "\e58c";
}
.material-icons.outbond:before {
  content: "\f228";
}
.material-icons.outbound:before {
  content: "\e1ca";
}
.material-icons.outbox:before {
  content: "\ef5f";
}
.material-icons.outdoor_grill:before {
  content: "\ea47";
}
.material-icons.outgoing_mail:before {
  content: "\f0d2";
}
.material-icons.outlet:before {
  content: "\f1d4";
}
.material-icons.outlined_flag:before {
  content: "\e16e";
}
.material-icons.output:before {
  content: "\ebbe";
}
.material-icons.padding:before {
  content: "\e9c8";
}
.material-icons.pages:before {
  content: "\e7f9";
}
.material-icons.pageview:before {
  content: "\e8a0";
}
.material-icons.paid:before {
  content: "\f041";
}
.material-icons.palette:before {
  content: "\e40a";
}
.material-icons.pan_tool:before {
  content: "\e925";
}
.material-icons.pan_tool_alt:before {
  content: "\ebb9";
}
.material-icons.panorama:before {
  content: "\e40b";
}
.material-icons.panorama_fish_eye:before {
  content: "\e40c";
}
.material-icons.panorama_fisheye:before {
  content: "\e40c";
}
.material-icons.panorama_horizontal:before {
  content: "\e40d";
}
.material-icons.panorama_horizontal_select:before {
  content: "\ef60";
}
.material-icons.panorama_photosphere:before {
  content: "\e9c9";
}
.material-icons.panorama_photosphere_select:before {
  content: "\e9ca";
}
.material-icons.panorama_vertical:before {
  content: "\e40e";
}
.material-icons.panorama_vertical_select:before {
  content: "\ef61";
}
.material-icons.panorama_wide_angle:before {
  content: "\e40f";
}
.material-icons.panorama_wide_angle_select:before {
  content: "\ef62";
}
.material-icons.paragliding:before {
  content: "\e50f";
}
.material-icons.park:before {
  content: "\ea63";
}
.material-icons.party_mode:before {
  content: "\e7fa";
}
.material-icons.password:before {
  content: "\f042";
}
.material-icons.pattern:before {
  content: "\f043";
}
.material-icons.pause:before {
  content: "\e034";
}
.material-icons.pause_circle:before {
  content: "\e1a2";
}
.material-icons.pause_circle_filled:before {
  content: "\e035";
}
.material-icons.pause_circle_outline:before {
  content: "\e036";
}
.material-icons.pause_presentation:before {
  content: "\e0ea";
}
.material-icons.payment:before {
  content: "\e8a1";
}
.material-icons.payments:before {
  content: "\ef63";
}
.material-icons.paypal:before {
  content: "\ea8d";
}
.material-icons.pedal_bike:before {
  content: "\eb29";
}
.material-icons.pending:before {
  content: "\ef64";
}
.material-icons.pending_actions:before {
  content: "\f1bb";
}
.material-icons.pentagon:before {
  content: "\eb50";
}
.material-icons.people:before {
  content: "\e7fb";
}
.material-icons.people_alt:before {
  content: "\ea21";
}
.material-icons.people_outline:before {
  content: "\e7fc";
}
.material-icons.percent:before {
  content: "\eb58";
}
.material-icons.perm_camera_mic:before {
  content: "\e8a2";
}
.material-icons.perm_contact_cal:before {
  content: "\e8a3";
}
.material-icons.perm_contact_calendar:before {
  content: "\e8a3";
}
.material-icons.perm_data_setting:before {
  content: "\e8a4";
}
.material-icons.perm_device_info:before {
  content: "\e8a5";
}
.material-icons.perm_device_information:before {
  content: "\e8a5";
}
.material-icons.perm_identity:before {
  content: "\e8a6";
}
.material-icons.perm_media:before {
  content: "\e8a7";
}
.material-icons.perm_phone_msg:before {
  content: "\e8a8";
}
.material-icons.perm_scan_wifi:before {
  content: "\e8a9";
}
.material-icons.person:before {
  content: "\e7fd";
}
.material-icons.person_add:before {
  content: "\e7fe";
}
.material-icons.person_add_alt:before {
  content: "\ea4d";
}
.material-icons.person_add_alt_1:before {
  content: "\ef65";
}
.material-icons.person_add_disabled:before {
  content: "\e9cb";
}
.material-icons.person_off:before {
  content: "\e510";
}
.material-icons.person_outline:before {
  content: "\e7ff";
}
.material-icons.person_pin:before {
  content: "\e55a";
}
.material-icons.person_pin_circle:before {
  content: "\e56a";
}
.material-icons.person_remove:before {
  content: "\ef66";
}
.material-icons.person_remove_alt_1:before {
  content: "\ef67";
}
.material-icons.person_search:before {
  content: "\f106";
}
.material-icons.personal_injury:before {
  content: "\e6da";
}
.material-icons.personal_video:before {
  content: "\e63b";
}
.material-icons.pest_control:before {
  content: "\f0fa";
}
.material-icons.pest_control_rodent:before {
  content: "\f0fd";
}
.material-icons.pets:before {
  content: "\e91d";
}
.material-icons.phishing:before {
  content: "\ead7";
}
.material-icons.phone:before {
  content: "\e0cd";
}
.material-icons.phone_android:before {
  content: "\e324";
}
.material-icons.phone_bluetooth_speaker:before {
  content: "\e61b";
}
.material-icons.phone_callback:before {
  content: "\e649";
}
.material-icons.phone_disabled:before {
  content: "\e9cc";
}
.material-icons.phone_enabled:before {
  content: "\e9cd";
}
.material-icons.phone_forwarded:before {
  content: "\e61c";
}
.material-icons.phone_in_talk:before {
  content: "\e61d";
}
.material-icons.phone_iphone:before {
  content: "\e325";
}
.material-icons.phone_locked:before {
  content: "\e61e";
}
.material-icons.phone_missed:before {
  content: "\e61f";
}
.material-icons.phone_paused:before {
  content: "\e620";
}
.material-icons.phonelink:before {
  content: "\e326";
}
.material-icons.phonelink_erase:before {
  content: "\e0db";
}
.material-icons.phonelink_lock:before {
  content: "\e0dc";
}
.material-icons.phonelink_off:before {
  content: "\e327";
}
.material-icons.phonelink_ring:before {
  content: "\e0dd";
}
.material-icons.phonelink_setup:before {
  content: "\e0de";
}
.material-icons.photo:before {
  content: "\e410";
}
.material-icons.photo_album:before {
  content: "\e411";
}
.material-icons.photo_camera:before {
  content: "\e412";
}
.material-icons.photo_camera_back:before {
  content: "\ef68";
}
.material-icons.photo_camera_front:before {
  content: "\ef69";
}
.material-icons.photo_filter:before {
  content: "\e43b";
}
.material-icons.photo_library:before {
  content: "\e413";
}
.material-icons.photo_size_select_actual:before {
  content: "\e432";
}
.material-icons.photo_size_select_large:before {
  content: "\e433";
}
.material-icons.photo_size_select_small:before {
  content: "\e434";
}
.material-icons.php:before {
  content: "\eb8f";
}
.material-icons.piano:before {
  content: "\e521";
}
.material-icons.piano_off:before {
  content: "\e520";
}
.material-icons.picture_as_pdf:before {
  content: "\e415";
}
.material-icons.picture_in_picture:before {
  content: "\e8aa";
}
.material-icons.picture_in_picture_alt:before {
  content: "\e911";
}
.material-icons.pie_chart:before {
  content: "\e6c4";
}
.material-icons.pie_chart_outline:before {
  content: "\f044";
}
.material-icons.pie_chart_outlined:before {
  content: "\e6c5";
}
.material-icons.pin:before {
  content: "\f045";
}
.material-icons.pin_drop:before {
  content: "\e55e";
}
.material-icons.pin_end:before {
  content: "\e767";
}
.material-icons.pin_invoke:before {
  content: "\e763";
}
.material-icons.pinch:before {
  content: "\eb38";
}
.material-icons.pivot_table_chart:before {
  content: "\e9ce";
}
.material-icons.pix:before {
  content: "\eaa3";
}
.material-icons.place:before {
  content: "\e55f";
}
.material-icons.plagiarism:before {
  content: "\ea5a";
}
.material-icons.play_arrow:before {
  content: "\e037";
}
.material-icons.play_circle:before {
  content: "\e1c4";
}
.material-icons.play_circle_fill:before {
  content: "\e038";
}
.material-icons.play_circle_filled:before {
  content: "\e038";
}
.material-icons.play_circle_outline:before {
  content: "\e039";
}
.material-icons.play_disabled:before {
  content: "\ef6a";
}
.material-icons.play_for_work:before {
  content: "\e906";
}
.material-icons.play_lesson:before {
  content: "\f047";
}
.material-icons.playlist_add:before {
  content: "\e03b";
}
.material-icons.playlist_add_check:before {
  content: "\e065";
}
.material-icons.playlist_add_check_circle:before {
  content: "\e7e6";
}
.material-icons.playlist_add_circle:before {
  content: "\e7e5";
}
.material-icons.playlist_play:before {
  content: "\e05f";
}
.material-icons.playlist_remove:before {
  content: "\eb80";
}
.material-icons.plumbing:before {
  content: "\f107";
}
.material-icons.plus_one:before {
  content: "\e800";
}
.material-icons.podcasts:before {
  content: "\f048";
}
.material-icons.point_of_sale:before {
  content: "\f17e";
}
.material-icons.policy:before {
  content: "\ea17";
}
.material-icons.poll:before {
  content: "\e801";
}
.material-icons.polyline:before {
  content: "\ebbb";
}
.material-icons.polymer:before {
  content: "\e8ab";
}
.material-icons.pool:before {
  content: "\eb48";
}
.material-icons.portable_wifi_off:before {
  content: "\e0ce";
}
.material-icons.portrait:before {
  content: "\e416";
}
.material-icons.post_add:before {
  content: "\ea20";
}
.material-icons.power:before {
  content: "\e63c";
}
.material-icons.power_input:before {
  content: "\e336";
}
.material-icons.power_off:before {
  content: "\e646";
}
.material-icons.power_settings_new:before {
  content: "\e8ac";
}
.material-icons.precision_manufacturing:before {
  content: "\f049";
}
.material-icons.pregnant_woman:before {
  content: "\e91e";
}
.material-icons.present_to_all:before {
  content: "\e0df";
}
.material-icons.preview:before {
  content: "\f1c5";
}
.material-icons.price_change:before {
  content: "\f04a";
}
.material-icons.price_check:before {
  content: "\f04b";
}
.material-icons.print:before {
  content: "\e8ad";
}
.material-icons.print_disabled:before {
  content: "\e9cf";
}
.material-icons.priority_high:before {
  content: "\e645";
}
.material-icons.privacy_tip:before {
  content: "\f0dc";
}
.material-icons.private_connectivity:before {
  content: "\e744";
}
.material-icons.production_quantity_limits:before {
  content: "\e1d1";
}
.material-icons.propane:before {
  content: "\ec14";
}
.material-icons.propane_tank:before {
  content: "\ec13";
}
.material-icons.psychology:before {
  content: "\ea4a";
}
.material-icons.psychology_alt:before {
  content: "\f8ea";
}
.material-icons.public:before {
  content: "\e80b";
}
.material-icons.public_off:before {
  content: "\f1ca";
}
.material-icons.publish:before {
  content: "\e255";
}
.material-icons.published_with_changes:before {
  content: "\f232";
}
.material-icons.punch_clock:before {
  content: "\eaa8";
}
.material-icons.push_pin:before {
  content: "\f10d";
}
.material-icons.qr_code:before {
  content: "\ef6b";
}
.material-icons.qr_code_2:before {
  content: "\e00a";
}
.material-icons.qr_code_scanner:before {
  content: "\f206";
}
.material-icons.query_builder:before {
  content: "\e8ae";
}
.material-icons.query_stats:before {
  content: "\e4fc";
}
.material-icons.question_answer:before {
  content: "\e8af";
}
.material-icons.question_mark:before {
  content: "\eb8b";
}
.material-icons.queue:before {
  content: "\e03c";
}
.material-icons.queue_music:before {
  content: "\e03d";
}
.material-icons.queue_play_next:before {
  content: "\e066";
}
.material-icons.quick_contacts_dialer:before {
  content: "\e0cf";
}
.material-icons.quick_contacts_mail:before {
  content: "\e0d0";
}
.material-icons.quickreply:before {
  content: "\ef6c";
}
.material-icons.quiz:before {
  content: "\f04c";
}
.material-icons.quora:before {
  content: "\ea98";
}
.material-icons.r_mobiledata:before {
  content: "\f04d";
}
.material-icons.radar:before {
  content: "\f04e";
}
.material-icons.radio:before {
  content: "\e03e";
}
.material-icons.radio_button_checked:before {
  content: "\e837";
}
.material-icons.radio_button_off:before {
  content: "\e836";
}
.material-icons.radio_button_on:before {
  content: "\e837";
}
.material-icons.radio_button_unchecked:before {
  content: "\e836";
}
.material-icons.railway_alert:before {
  content: "\e9d1";
}
.material-icons.ramen_dining:before {
  content: "\ea64";
}
.material-icons.ramp_left:before {
  content: "\eb9c";
}
.material-icons.ramp_right:before {
  content: "\eb96";
}
.material-icons.rate_review:before {
  content: "\e560";
}
.material-icons.raw_off:before {
  content: "\f04f";
}
.material-icons.raw_on:before {
  content: "\f050";
}
.material-icons.read_more:before {
  content: "\ef6d";
}
.material-icons.real_estate_agent:before {
  content: "\e73a";
}
.material-icons.receipt:before {
  content: "\e8b0";
}
.material-icons.receipt_long:before {
  content: "\ef6e";
}
.material-icons.recent_actors:before {
  content: "\e03f";
}
.material-icons.recommend:before {
  content: "\e9d2";
}
.material-icons.record_voice_over:before {
  content: "\e91f";
}
.material-icons.rectangle:before {
  content: "\eb54";
}
.material-icons.recycling:before {
  content: "\e760";
}
.material-icons.reddit:before {
  content: "\eaa0";
}
.material-icons.redeem:before {
  content: "\e8b1";
}
.material-icons.redo:before {
  content: "\e15a";
}
.material-icons.reduce_capacity:before {
  content: "\f21c";
}
.material-icons.refresh:before {
  content: "\e5d5";
}
.material-icons.remember_me:before {
  content: "\f051";
}
.material-icons.remove:before {
  content: "\e15b";
}
.material-icons.remove_circle:before {
  content: "\e15c";
}
.material-icons.remove_circle_outline:before {
  content: "\e15d";
}
.material-icons.remove_done:before {
  content: "\e9d3";
}
.material-icons.remove_from_queue:before {
  content: "\e067";
}
.material-icons.remove_moderator:before {
  content: "\e9d4";
}
.material-icons.remove_red_eye:before {
  content: "\e417";
}
.material-icons.remove_road:before {
  content: "\ebfc";
}
.material-icons.remove_shopping_cart:before {
  content: "\e928";
}
.material-icons.reorder:before {
  content: "\e8fe";
}
.material-icons.repartition:before {
  content: "\f8e8";
}
.material-icons.repeat:before {
  content: "\e040";
}
.material-icons.repeat_on:before {
  content: "\e9d6";
}
.material-icons.repeat_one:before {
  content: "\e041";
}
.material-icons.repeat_one_on:before {
  content: "\e9d7";
}
.material-icons.replay:before {
  content: "\e042";
}
.material-icons.replay_10:before {
  content: "\e059";
}
.material-icons.replay_30:before {
  content: "\e05a";
}
.material-icons.replay_5:before {
  content: "\e05b";
}
.material-icons.replay_circle_filled:before {
  content: "\e9d8";
}
.material-icons.reply:before {
  content: "\e15e";
}
.material-icons.reply_all:before {
  content: "\e15f";
}
.material-icons.report:before {
  content: "\e160";
}
.material-icons.report_gmailerrorred:before {
  content: "\f052";
}
.material-icons.report_off:before {
  content: "\e170";
}
.material-icons.report_problem:before {
  content: "\e8b2";
}
.material-icons.request_page:before {
  content: "\f22c";
}
.material-icons.request_quote:before {
  content: "\f1b6";
}
.material-icons.reset_tv:before {
  content: "\e9d9";
}
.material-icons.restart_alt:before {
  content: "\f053";
}
.material-icons.restaurant:before {
  content: "\e56c";
}
.material-icons.restaurant_menu:before {
  content: "\e561";
}
.material-icons.restore:before {
  content: "\e8b3";
}
.material-icons.restore_from_trash:before {
  content: "\e938";
}
.material-icons.restore_page:before {
  content: "\e929";
}
.material-icons.reviews:before {
  content: "\f054";
}
.material-icons.rice_bowl:before {
  content: "\f1f5";
}
.material-icons.ring_volume:before {
  content: "\e0d1";
}
.material-icons.rocket:before {
  content: "\eba5";
}
.material-icons.rocket_launch:before {
  content: "\eb9b";
}
.material-icons.roller_shades:before {
  content: "\ec12";
}
.material-icons.roller_shades_closed:before {
  content: "\ec11";
}
.material-icons.roller_skating:before {
  content: "\ebcd";
}
.material-icons.roofing:before {
  content: "\f201";
}
.material-icons.room:before {
  content: "\e8b4";
}
.material-icons.room_preferences:before {
  content: "\f1b8";
}
.material-icons.room_service:before {
  content: "\eb49";
}
.material-icons.rotate_90_degrees_ccw:before {
  content: "\e418";
}
.material-icons.rotate_90_degrees_cw:before {
  content: "\eaab";
}
.material-icons.rotate_left:before {
  content: "\e419";
}
.material-icons.rotate_right:before {
  content: "\e41a";
}
.material-icons.roundabout_left:before {
  content: "\eb99";
}
.material-icons.roundabout_right:before {
  content: "\eba3";
}
.material-icons.rounded_corner:before {
  content: "\e920";
}
.material-icons.route:before {
  content: "\eacd";
}
.material-icons.router:before {
  content: "\e328";
}
.material-icons.rowing:before {
  content: "\e921";
}
.material-icons.rss_feed:before {
  content: "\e0e5";
}
.material-icons.rsvp:before {
  content: "\f055";
}
.material-icons.rtt:before {
  content: "\e9ad";
}
.material-icons.rule:before {
  content: "\f1c2";
}
.material-icons.rule_folder:before {
  content: "\f1c9";
}
.material-icons.run_circle:before {
  content: "\ef6f";
}
.material-icons.running_with_errors:before {
  content: "\e51d";
}
.material-icons.rv_hookup:before {
  content: "\e642";
}
.material-icons.safety_check:before {
  content: "\ebef";
}
.material-icons.safety_divider:before {
  content: "\e1cc";
}
.material-icons.sailing:before {
  content: "\e502";
}
.material-icons.sanitizer:before {
  content: "\f21d";
}
.material-icons.satellite:before {
  content: "\e562";
}
.material-icons.satellite_alt:before {
  content: "\eb3a";
}
.material-icons.save:before {
  content: "\e161";
}
.material-icons.save_alt:before {
  content: "\e171";
}
.material-icons.save_as:before {
  content: "\eb60";
}
.material-icons.saved_search:before {
  content: "\ea11";
}
.material-icons.savings:before {
  content: "\e2eb";
}
.material-icons.scale:before {
  content: "\eb5f";
}
.material-icons.scanner:before {
  content: "\e329";
}
.material-icons.scatter_plot:before {
  content: "\e268";
}
.material-icons.schedule:before {
  content: "\e8b5";
}
.material-icons.schedule_send:before {
  content: "\ea0a";
}
.material-icons.schema:before {
  content: "\e4fd";
}
.material-icons.school:before {
  content: "\e80c";
}
.material-icons.science:before {
  content: "\ea4b";
}
.material-icons.score:before {
  content: "\e269";
}
.material-icons.scoreboard:before {
  content: "\ebd0";
}
.material-icons.screen_lock_landscape:before {
  content: "\e1be";
}
.material-icons.screen_lock_portrait:before {
  content: "\e1bf";
}
.material-icons.screen_lock_rotation:before {
  content: "\e1c0";
}
.material-icons.screen_rotation:before {
  content: "\e1c1";
}
.material-icons.screen_rotation_alt:before {
  content: "\ebee";
}
.material-icons.screen_search_desktop:before {
  content: "\ef70";
}
.material-icons.screen_share:before {
  content: "\e0e2";
}
.material-icons.screenshot:before {
  content: "\f056";
}
.material-icons.screenshot_monitor:before {
  content: "\ec08";
}
.material-icons.scuba_diving:before {
  content: "\ebce";
}
.material-icons.sd:before {
  content: "\e9dd";
}
.material-icons.sd_card:before {
  content: "\e623";
}
.material-icons.sd_card_alert:before {
  content: "\f057";
}
.material-icons.sd_storage:before {
  content: "\e1c2";
}
.material-icons.search:before {
  content: "\e8b6";
}
.material-icons.search_off:before {
  content: "\ea76";
}
.material-icons.security:before {
  content: "\e32a";
}
.material-icons.security_update:before {
  content: "\f058";
}
.material-icons.security_update_good:before {
  content: "\f059";
}
.material-icons.security_update_warning:before {
  content: "\f05a";
}
.material-icons.segment:before {
  content: "\e94b";
}
.material-icons.select_all:before {
  content: "\e162";
}
.material-icons.self_improvement:before {
  content: "\ea78";
}
.material-icons.sell:before {
  content: "\f05b";
}
.material-icons.send:before {
  content: "\e163";
}
.material-icons.send_and_archive:before {
  content: "\ea0c";
}
.material-icons.send_time_extension:before {
  content: "\eadb";
}
.material-icons.send_to_mobile:before {
  content: "\f05c";
}
.material-icons.sensor_door:before {
  content: "\f1b5";
}
.material-icons.sensor_occupied:before {
  content: "\ec10";
}
.material-icons.sensor_window:before {
  content: "\f1b4";
}
.material-icons.sensors:before {
  content: "\e51e";
}
.material-icons.sensors_off:before {
  content: "\e51f";
}
.material-icons.sentiment_dissatisfied:before {
  content: "\e811";
}
.material-icons.sentiment_neutral:before {
  content: "\e812";
}
.material-icons.sentiment_satisfied:before {
  content: "\e813";
}
.material-icons.sentiment_satisfied_alt:before {
  content: "\e0ed";
}
.material-icons.sentiment_very_dissatisfied:before {
  content: "\e814";
}
.material-icons.sentiment_very_satisfied:before {
  content: "\e815";
}
.material-icons.set_meal:before {
  content: "\f1ea";
}
.material-icons.settings:before {
  content: "\e8b8";
}
.material-icons.settings_accessibility:before {
  content: "\f05d";
}
.material-icons.settings_applications:before {
  content: "\e8b9";
}
.material-icons.settings_backup_restore:before {
  content: "\e8ba";
}
.material-icons.settings_bluetooth:before {
  content: "\e8bb";
}
.material-icons.settings_brightness:before {
  content: "\e8bd";
}
.material-icons.settings_cell:before {
  content: "\e8bc";
}
.material-icons.settings_display:before {
  content: "\e8bd";
}
.material-icons.settings_ethernet:before {
  content: "\e8be";
}
.material-icons.settings_input_antenna:before {
  content: "\e8bf";
}
.material-icons.settings_input_component:before {
  content: "\e8c0";
}
.material-icons.settings_input_composite:before {
  content: "\e8c1";
}
.material-icons.settings_input_hdmi:before {
  content: "\e8c2";
}
.material-icons.settings_input_svideo:before {
  content: "\e8c3";
}
.material-icons.settings_overscan:before {
  content: "\e8c4";
}
.material-icons.settings_phone:before {
  content: "\e8c5";
}
.material-icons.settings_power:before {
  content: "\e8c6";
}
.material-icons.settings_remote:before {
  content: "\e8c7";
}
.material-icons.settings_suggest:before {
  content: "\f05e";
}
.material-icons.settings_system_daydream:before {
  content: "\e1c3";
}
.material-icons.settings_voice:before {
  content: "\e8c8";
}
.material-icons.severe_cold:before {
  content: "\ebd3";
}
.material-icons.share:before {
  content: "\e80d";
}
.material-icons.share_arrival_time:before {
  content: "\e524";
}
.material-icons.share_location:before {
  content: "\f05f";
}
.material-icons.shield:before {
  content: "\e9e0";
}
.material-icons.shield_moon:before {
  content: "\eaa9";
}
.material-icons.shop:before {
  content: "\e8c9";
}
.material-icons.shop_2:before {
  content: "\e19e";
}
.material-icons.shop_two:before {
  content: "\e8ca";
}
.material-icons.shopify:before {
  content: "\ea9d";
}
.material-icons.shopping_bag:before {
  content: "\f1cc";
}
.material-icons.shopping_basket:before {
  content: "\e8cb";
}
.material-icons.shopping_cart:before {
  content: "\e8cc";
}
.material-icons.shopping_cart_checkout:before {
  content: "\eb88";
}
.material-icons.short_text:before {
  content: "\e261";
}
.material-icons.shortcut:before {
  content: "\f060";
}
.material-icons.show_chart:before {
  content: "\e6e1";
}
.material-icons.shower:before {
  content: "\f061";
}
.material-icons.shuffle:before {
  content: "\e043";
}
.material-icons.shuffle_on:before {
  content: "\e9e1";
}
.material-icons.shutter_speed:before {
  content: "\e43d";
}
.material-icons.sick:before {
  content: "\f220";
}
.material-icons.sign_language:before {
  content: "\ebe5";
}
.material-icons.signal_cellular_0_bar:before {
  content: "\f0a8";
}
.material-icons.signal_cellular_4_bar:before {
  content: "\e1c8";
}
.material-icons.signal_cellular_alt:before {
  content: "\e202";
}
.material-icons.signal_cellular_alt_1_bar:before {
  content: "\ebdf";
}
.material-icons.signal_cellular_alt_2_bar:before {
  content: "\ebe3";
}
.material-icons.signal_cellular_connected_no_internet_0_bar:before {
  content: "\f0ac";
}
.material-icons.signal_cellular_connected_no_internet_4_bar:before {
  content: "\e1cd";
}
.material-icons.signal_cellular_no_sim:before {
  content: "\e1ce";
}
.material-icons.signal_cellular_nodata:before {
  content: "\f062";
}
.material-icons.signal_cellular_null:before {
  content: "\e1cf";
}
.material-icons.signal_cellular_off:before {
  content: "\e1d0";
}
.material-icons.signal_wifi_0_bar:before {
  content: "\f0b0";
}
.material-icons.signal_wifi_4_bar:before {
  content: "\e1d8";
}
.material-icons.signal_wifi_4_bar_lock:before {
  content: "\e1d9";
}
.material-icons.signal_wifi_bad:before {
  content: "\f063";
}
.material-icons.signal_wifi_connected_no_internet_4:before {
  content: "\f064";
}
.material-icons.signal_wifi_off:before {
  content: "\e1da";
}
.material-icons.signal_wifi_statusbar_4_bar:before {
  content: "\f065";
}
.material-icons.signal_wifi_statusbar_connected_no_internet_4:before {
  content: "\f066";
}
.material-icons.signal_wifi_statusbar_null:before {
  content: "\f067";
}
.material-icons.signpost:before {
  content: "\eb91";
}
.material-icons.sim_card:before {
  content: "\e32b";
}
.material-icons.sim_card_alert:before {
  content: "\e624";
}
.material-icons.sim_card_download:before {
  content: "\f068";
}
.material-icons.single_bed:before {
  content: "\ea48";
}
.material-icons.sip:before {
  content: "\f069";
}
.material-icons.skateboarding:before {
  content: "\e511";
}
.material-icons.skip_next:before {
  content: "\e044";
}
.material-icons.skip_previous:before {
  content: "\e045";
}
.material-icons.sledding:before {
  content: "\e512";
}
.material-icons.slideshow:before {
  content: "\e41b";
}
.material-icons.slow_motion_video:before {
  content: "\e068";
}
.material-icons.smart_button:before {
  content: "\f1c1";
}
.material-icons.smart_display:before {
  content: "\f06a";
}
.material-icons.smart_screen:before {
  content: "\f06b";
}
.material-icons.smart_toy:before {
  content: "\f06c";
}
.material-icons.smartphone:before {
  content: "\e32c";
}
.material-icons.smoke_free:before {
  content: "\eb4a";
}
.material-icons.smoking_rooms:before {
  content: "\eb4b";
}
.material-icons.sms:before {
  content: "\e625";
}
.material-icons.sms_failed:before {
  content: "\e626";
}
.material-icons.snapchat:before {
  content: "\ea6e";
}
.material-icons.snippet_folder:before {
  content: "\f1c7";
}
.material-icons.snooze:before {
  content: "\e046";
}
.material-icons.snowboarding:before {
  content: "\e513";
}
.material-icons.snowing:before {
  content: "\e80f";
}
.material-icons.snowmobile:before {
  content: "\e503";
}
.material-icons.snowshoeing:before {
  content: "\e514";
}
.material-icons.soap:before {
  content: "\f1b2";
}
.material-icons.social_distance:before {
  content: "\e1cb";
}
.material-icons.solar_power:before {
  content: "\ec0f";
}
.material-icons.sort:before {
  content: "\e164";
}
.material-icons.sort_by_alpha:before {
  content: "\e053";
}
.material-icons.sos:before {
  content: "\ebf7";
}
.material-icons.soup_kitchen:before {
  content: "\e7d3";
}
.material-icons.source:before {
  content: "\f1c4";
}
.material-icons.south:before {
  content: "\f1e3";
}
.material-icons.south_america:before {
  content: "\e7e4";
}
.material-icons.south_east:before {
  content: "\f1e4";
}
.material-icons.south_west:before {
  content: "\f1e5";
}
.material-icons.spa:before {
  content: "\eb4c";
}
.material-icons.space_bar:before {
  content: "\e256";
}
.material-icons.space_dashboard:before {
  content: "\e66b";
}
.material-icons.spatial_audio:before {
  content: "\ebeb";
}
.material-icons.spatial_audio_off:before {
  content: "\ebe8";
}
.material-icons.spatial_tracking:before {
  content: "\ebea";
}
.material-icons.speaker:before {
  content: "\e32d";
}
.material-icons.speaker_group:before {
  content: "\e32e";
}
.material-icons.speaker_notes:before {
  content: "\e8cd";
}
.material-icons.speaker_notes_off:before {
  content: "\e92a";
}
.material-icons.speaker_phone:before {
  content: "\e0d2";
}
.material-icons.speed:before {
  content: "\e9e4";
}
.material-icons.spellcheck:before {
  content: "\e8ce";
}
.material-icons.splitscreen:before {
  content: "\f06d";
}
.material-icons.spoke:before {
  content: "\e9a7";
}
.material-icons.sports:before {
  content: "\ea30";
}
.material-icons.sports_bar:before {
  content: "\f1f3";
}
.material-icons.sports_baseball:before {
  content: "\ea51";
}
.material-icons.sports_basketball:before {
  content: "\ea26";
}
.material-icons.sports_cricket:before {
  content: "\ea27";
}
.material-icons.sports_esports:before {
  content: "\ea28";
}
.material-icons.sports_football:before {
  content: "\ea29";
}
.material-icons.sports_golf:before {
  content: "\ea2a";
}
.material-icons.sports_gymnastics:before {
  content: "\ebc4";
}
.material-icons.sports_handball:before {
  content: "\ea33";
}
.material-icons.sports_hockey:before {
  content: "\ea2b";
}
.material-icons.sports_kabaddi:before {
  content: "\ea34";
}
.material-icons.sports_martial_arts:before {
  content: "\eae9";
}
.material-icons.sports_mma:before {
  content: "\ea2c";
}
.material-icons.sports_motorsports:before {
  content: "\ea2d";
}
.material-icons.sports_rugby:before {
  content: "\ea2e";
}
.material-icons.sports_score:before {
  content: "\f06e";
}
.material-icons.sports_soccer:before {
  content: "\ea2f";
}
.material-icons.sports_tennis:before {
  content: "\ea32";
}
.material-icons.sports_volleyball:before {
  content: "\ea31";
}
.material-icons.square:before {
  content: "\eb36";
}
.material-icons.square_foot:before {
  content: "\ea49";
}
.material-icons.ssid_chart:before {
  content: "\eb66";
}
.material-icons.stacked_bar_chart:before {
  content: "\e9e6";
}
.material-icons.stacked_line_chart:before {
  content: "\f22b";
}
.material-icons.stadium:before {
  content: "\eb90";
}
.material-icons.stairs:before {
  content: "\f1a9";
}
.material-icons.star:before {
  content: "\e838";
}
.material-icons.star_border:before {
  content: "\e83a";
}
.material-icons.star_border_purple500:before {
  content: "\f099";
}
.material-icons.star_half:before {
  content: "\e839";
}
.material-icons.star_outline:before {
  content: "\f06f";
}
.material-icons.star_purple500:before {
  content: "\f09a";
}
.material-icons.star_rate:before {
  content: "\f0ec";
}
.material-icons.stars:before {
  content: "\e8d0";
}
.material-icons.start:before {
  content: "\e089";
}
.material-icons.stay_current_landscape:before {
  content: "\e0d3";
}
.material-icons.stay_current_portrait:before {
  content: "\e0d4";
}
.material-icons.stay_primary_landscape:before {
  content: "\e0d5";
}
.material-icons.stay_primary_portrait:before {
  content: "\e0d6";
}
.material-icons.sticky_note_2:before {
  content: "\f1fc";
}
.material-icons.stop:before {
  content: "\e047";
}
.material-icons.stop_circle:before {
  content: "\ef71";
}
.material-icons.stop_screen_share:before {
  content: "\e0e3";
}
.material-icons.storage:before {
  content: "\e1db";
}
.material-icons.store:before {
  content: "\e8d1";
}
.material-icons.store_mall_directory:before {
  content: "\e563";
}
.material-icons.storefront:before {
  content: "\ea12";
}
.material-icons.storm:before {
  content: "\f070";
}
.material-icons.straight:before {
  content: "\eb95";
}
.material-icons.straighten:before {
  content: "\e41c";
}
.material-icons.stream:before {
  content: "\e9e9";
}
.material-icons.streetview:before {
  content: "\e56e";
}
.material-icons.strikethrough_s:before {
  content: "\e257";
}
.material-icons.stroller:before {
  content: "\f1ae";
}
.material-icons.style:before {
  content: "\e41d";
}
.material-icons.subdirectory_arrow_left:before {
  content: "\e5d9";
}
.material-icons.subdirectory_arrow_right:before {
  content: "\e5da";
}
.material-icons.subject:before {
  content: "\e8d2";
}
.material-icons.subscript:before {
  content: "\f111";
}
.material-icons.subscriptions:before {
  content: "\e064";
}
.material-icons.subtitles:before {
  content: "\e048";
}
.material-icons.subtitles_off:before {
  content: "\ef72";
}
.material-icons.subway:before {
  content: "\e56f";
}
.material-icons.summarize:before {
  content: "\f071";
}
.material-icons.sunny:before {
  content: "\e81a";
}
.material-icons.sunny_snowing:before {
  content: "\e819";
}
.material-icons.superscript:before {
  content: "\f112";
}
.material-icons.supervised_user_circle:before {
  content: "\e939";
}
.material-icons.supervisor_account:before {
  content: "\e8d3";
}
.material-icons.support:before {
  content: "\ef73";
}
.material-icons.support_agent:before {
  content: "\f0e2";
}
.material-icons.surfing:before {
  content: "\e515";
}
.material-icons.surround_sound:before {
  content: "\e049";
}
.material-icons.swap_calls:before {
  content: "\e0d7";
}
.material-icons.swap_horiz:before {
  content: "\e8d4";
}
.material-icons.swap_horizontal_circle:before {
  content: "\e933";
}
.material-icons.swap_vert:before {
  content: "\e8d5";
}
.material-icons.swap_vert_circle:before {
  content: "\e8d6";
}
.material-icons.swap_vertical_circle:before {
  content: "\e8d6";
}
.material-icons.swipe:before {
  content: "\e9ec";
}
.material-icons.swipe_down:before {
  content: "\eb53";
}
.material-icons.swipe_down_alt:before {
  content: "\eb30";
}
.material-icons.swipe_left:before {
  content: "\eb59";
}
.material-icons.swipe_left_alt:before {
  content: "\eb33";
}
.material-icons.swipe_right:before {
  content: "\eb52";
}
.material-icons.swipe_right_alt:before {
  content: "\eb56";
}
.material-icons.swipe_up:before {
  content: "\eb2e";
}
.material-icons.swipe_up_alt:before {
  content: "\eb35";
}
.material-icons.swipe_vertical:before {
  content: "\eb51";
}
.material-icons.switch_access_shortcut:before {
  content: "\e7e1";
}
.material-icons.switch_access_shortcut_add:before {
  content: "\e7e2";
}
.material-icons.switch_account:before {
  content: "\e9ed";
}
.material-icons.switch_camera:before {
  content: "\e41e";
}
.material-icons.switch_left:before {
  content: "\f1d1";
}
.material-icons.switch_right:before {
  content: "\f1d2";
}
.material-icons.switch_video:before {
  content: "\e41f";
}
.material-icons.synagogue:before {
  content: "\eab0";
}
.material-icons.sync:before {
  content: "\e627";
}
.material-icons.sync_alt:before {
  content: "\ea18";
}
.material-icons.sync_disabled:before {
  content: "\e628";
}
.material-icons.sync_lock:before {
  content: "\eaee";
}
.material-icons.sync_problem:before {
  content: "\e629";
}
.material-icons.system_security_update:before {
  content: "\f072";
}
.material-icons.system_security_update_good:before {
  content: "\f073";
}
.material-icons.system_security_update_warning:before {
  content: "\f074";
}
.material-icons.system_update:before {
  content: "\e62a";
}
.material-icons.system_update_alt:before {
  content: "\e8d7";
}
.material-icons.system_update_tv:before {
  content: "\e8d7";
}
.material-icons.tab:before {
  content: "\e8d8";
}
.material-icons.tab_unselected:before {
  content: "\e8d9";
}
.material-icons.table_bar:before {
  content: "\ead2";
}
.material-icons.table_chart:before {
  content: "\e265";
}
.material-icons.table_restaurant:before {
  content: "\eac6";
}
.material-icons.table_rows:before {
  content: "\f101";
}
.material-icons.table_view:before {
  content: "\f1be";
}
.material-icons.tablet:before {
  content: "\e32f";
}
.material-icons.tablet_android:before {
  content: "\e330";
}
.material-icons.tablet_mac:before {
  content: "\e331";
}
.material-icons.tag:before {
  content: "\e9ef";
}
.material-icons.tag_faces:before {
  content: "\e420";
}
.material-icons.takeout_dining:before {
  content: "\ea74";
}
.material-icons.tap_and_play:before {
  content: "\e62b";
}
.material-icons.tapas:before {
  content: "\f1e9";
}
.material-icons.task:before {
  content: "\f075";
}
.material-icons.task_alt:before {
  content: "\e2e6";
}
.material-icons.taxi_alert:before {
  content: "\ef74";
}
.material-icons.telegram:before {
  content: "\ea6b";
}
.material-icons.temple_buddhist:before {
  content: "\eab3";
}
.material-icons.temple_hindu:before {
  content: "\eaaf";
}
.material-icons.terminal:before {
  content: "\eb8e";
}
.material-icons.terrain:before {
  content: "\e564";
}
.material-icons.text_decrease:before {
  content: "\eadd";
}
.material-icons.text_fields:before {
  content: "\e262";
}
.material-icons.text_format:before {
  content: "\e165";
}
.material-icons.text_increase:before {
  content: "\eae2";
}
.material-icons.text_rotate_up:before {
  content: "\e93a";
}
.material-icons.text_rotate_vertical:before {
  content: "\e93b";
}
.material-icons.text_rotation_angledown:before {
  content: "\e93c";
}
.material-icons.text_rotation_angleup:before {
  content: "\e93d";
}
.material-icons.text_rotation_down:before {
  content: "\e93e";
}
.material-icons.text_rotation_none:before {
  content: "\e93f";
}
.material-icons.text_snippet:before {
  content: "\f1c6";
}
.material-icons.textsms:before {
  content: "\e0d8";
}
.material-icons.texture:before {
  content: "\e421";
}
.material-icons.theater_comedy:before {
  content: "\ea66";
}
.material-icons.theaters:before {
  content: "\e8da";
}
.material-icons.thermostat:before {
  content: "\f076";
}
.material-icons.thermostat_auto:before {
  content: "\f077";
}
.material-icons.thumb_down:before {
  content: "\e8db";
}
.material-icons.thumb_down_alt:before {
  content: "\e816";
}
.material-icons.thumb_down_off_alt:before {
  content: "\e9f2";
}
.material-icons.thumb_up:before {
  content: "\e8dc";
}
.material-icons.thumb_up_alt:before {
  content: "\e817";
}
.material-icons.thumb_up_off_alt:before {
  content: "\e9f3";
}
.material-icons.thumbs_up_down:before {
  content: "\e8dd";
}
.material-icons.thunderstorm:before {
  content: "\ebdb";
}
.material-icons.tiktok:before {
  content: "\ea7e";
}
.material-icons.time_to_leave:before {
  content: "\e62c";
}
.material-icons.timelapse:before {
  content: "\e422";
}
.material-icons.timeline:before {
  content: "\e922";
}
.material-icons.timer:before {
  content: "\e425";
}
.material-icons.timer_10:before {
  content: "\e423";
}
.material-icons.timer_10_select:before {
  content: "\f07a";
}
.material-icons.timer_3:before {
  content: "\e424";
}
.material-icons.timer_3_select:before {
  content: "\f07b";
}
.material-icons.timer_off:before {
  content: "\e426";
}
.material-icons.tips_and_updates:before {
  content: "\e79a";
}
.material-icons.tire_repair:before {
  content: "\ebc8";
}
.material-icons.title:before {
  content: "\e264";
}
.material-icons.toc:before {
  content: "\e8de";
}
.material-icons.today:before {
  content: "\e8df";
}
.material-icons.toggle_off:before {
  content: "\e9f5";
}
.material-icons.toggle_on:before {
  content: "\e9f6";
}
.material-icons.token:before {
  content: "\ea25";
}
.material-icons.toll:before {
  content: "\e8e0";
}
.material-icons.tonality:before {
  content: "\e427";
}
.material-icons.topic:before {
  content: "\f1c8";
}
.material-icons.tornado:before {
  content: "\e199";
}
.material-icons.touch_app:before {
  content: "\e913";
}
.material-icons.tour:before {
  content: "\ef75";
}
.material-icons.toys:before {
  content: "\e332";
}
.material-icons.track_changes:before {
  content: "\e8e1";
}
.material-icons.traffic:before {
  content: "\e565";
}
.material-icons.train:before {
  content: "\e570";
}
.material-icons.tram:before {
  content: "\e571";
}
.material-icons.transcribe:before {
  content: "\f8ec";
}
.material-icons.transfer_within_a_station:before {
  content: "\e572";
}
.material-icons.transform:before {
  content: "\e428";
}
.material-icons.transgender:before {
  content: "\e58d";
}
.material-icons.transit_enterexit:before {
  content: "\e579";
}
.material-icons.translate:before {
  content: "\e8e2";
}
.material-icons.travel_explore:before {
  content: "\e2db";
}
.material-icons.trending_down:before {
  content: "\e8e3";
}
.material-icons.trending_flat:before {
  content: "\e8e4";
}
.material-icons.trending_neutral:before {
  content: "\e8e4";
}
.material-icons.trending_up:before {
  content: "\e8e5";
}
.material-icons.trip_origin:before {
  content: "\e57b";
}
.material-icons.troubleshoot:before {
  content: "\e1d2";
}
.material-icons.try:before {
  content: "\f07c";
}
.material-icons.tsunami:before {
  content: "\ebd8";
}
.material-icons.tty:before {
  content: "\f1aa";
}
.material-icons.tune:before {
  content: "\e429";
}
.material-icons.tungsten:before {
  content: "\f07d";
}
.material-icons.turn_left:before {
  content: "\eba6";
}
.material-icons.turn_right:before {
  content: "\ebab";
}
.material-icons.turn_sharp_left:before {
  content: "\eba7";
}
.material-icons.turn_sharp_right:before {
  content: "\ebaa";
}
.material-icons.turn_slight_left:before {
  content: "\eba4";
}
.material-icons.turn_slight_right:before {
  content: "\eb9a";
}
.material-icons.turned_in:before {
  content: "\e8e6";
}
.material-icons.turned_in_not:before {
  content: "\e8e7";
}
.material-icons.tv:before {
  content: "\e333";
}
.material-icons.tv_off:before {
  content: "\e647";
}
.material-icons.two_wheeler:before {
  content: "\e9f9";
}
.material-icons.type_specimen:before {
  content: "\f8f0";
}
.material-icons.u_turn_left:before {
  content: "\eba1";
}
.material-icons.u_turn_right:before {
  content: "\eba2";
}
.material-icons.umbrella:before {
  content: "\f1ad";
}
.material-icons.unarchive:before {
  content: "\e169";
}
.material-icons.undo:before {
  content: "\e166";
}
.material-icons.unfold_less:before {
  content: "\e5d6";
}
.material-icons.unfold_more:before {
  content: "\e5d7";
}
.material-icons.unpublished:before {
  content: "\f236";
}
.material-icons.unsubscribe:before {
  content: "\e0eb";
}
.material-icons.upcoming:before {
  content: "\f07e";
}
.material-icons.update:before {
  content: "\e923";
}
.material-icons.update_disabled:before {
  content: "\e075";
}
.material-icons.upgrade:before {
  content: "\f0fb";
}
.material-icons.upload:before {
  content: "\f09b";
}
.material-icons.upload_file:before {
  content: "\e9fc";
}
.material-icons.usb:before {
  content: "\e1e0";
}
.material-icons.usb_off:before {
  content: "\e4fa";
}
.material-icons.vaccines:before {
  content: "\e138";
}
.material-icons.vape_free:before {
  content: "\ebc6";
}
.material-icons.vaping_rooms:before {
  content: "\ebcf";
}
.material-icons.verified:before {
  content: "\ef76";
}
.material-icons.verified_user:before {
  content: "\e8e8";
}
.material-icons.vertical_align_bottom:before {
  content: "\e258";
}
.material-icons.vertical_align_center:before {
  content: "\e259";
}
.material-icons.vertical_align_top:before {
  content: "\e25a";
}
.material-icons.vertical_distribute:before {
  content: "\e076";
}
.material-icons.vertical_shades:before {
  content: "\ec0e";
}
.material-icons.vertical_shades_closed:before {
  content: "\ec0d";
}
.material-icons.vertical_split:before {
  content: "\e949";
}
.material-icons.vibration:before {
  content: "\e62d";
}
.material-icons.video_call:before {
  content: "\e070";
}
.material-icons.video_camera_back:before {
  content: "\f07f";
}
.material-icons.video_camera_front:before {
  content: "\f080";
}
.material-icons.video_collection:before {
  content: "\e04a";
}
.material-icons.video_file:before {
  content: "\eb87";
}
.material-icons.video_label:before {
  content: "\e071";
}
.material-icons.video_library:before {
  content: "\e04a";
}
.material-icons.video_settings:before {
  content: "\ea75";
}
.material-icons.video_stable:before {
  content: "\f081";
}
.material-icons.videocam:before {
  content: "\e04b";
}
.material-icons.videocam_off:before {
  content: "\e04c";
}
.material-icons.videogame_asset:before {
  content: "\e338";
}
.material-icons.videogame_asset_off:before {
  content: "\e500";
}
.material-icons.view_agenda:before {
  content: "\e8e9";
}
.material-icons.view_array:before {
  content: "\e8ea";
}
.material-icons.view_carousel:before {
  content: "\e8eb";
}
.material-icons.view_column:before {
  content: "\e8ec";
}
.material-icons.view_comfortable:before {
  content: "\e42a";
}
.material-icons.view_comfy:before {
  content: "\e42a";
}
.material-icons.view_comfy_alt:before {
  content: "\eb73";
}
.material-icons.view_compact:before {
  content: "\e42b";
}
.material-icons.view_compact_alt:before {
  content: "\eb74";
}
.material-icons.view_cozy:before {
  content: "\eb75";
}
.material-icons.view_day:before {
  content: "\e8ed";
}
.material-icons.view_headline:before {
  content: "\e8ee";
}
.material-icons.view_in_ar:before {
  content: "\e9fe";
}
.material-icons.view_kanban:before {
  content: "\eb7f";
}
.material-icons.view_list:before {
  content: "\e8ef";
}
.material-icons.view_module:before {
  content: "\e8f0";
}
.material-icons.view_quilt:before {
  content: "\e8f1";
}
.material-icons.view_sidebar:before {
  content: "\f114";
}
.material-icons.view_stream:before {
  content: "\e8f2";
}
.material-icons.view_timeline:before {
  content: "\eb85";
}
.material-icons.view_week:before {
  content: "\e8f3";
}
.material-icons.vignette:before {
  content: "\e435";
}
.material-icons.villa:before {
  content: "\e586";
}
.material-icons.visibility:before {
  content: "\e8f4";
}
.material-icons.visibility_off:before {
  content: "\e8f5";
}
.material-icons.voice_chat:before {
  content: "\e62e";
}
.material-icons.voice_over_off:before {
  content: "\e94a";
}
.material-icons.voicemail:before {
  content: "\e0d9";
}
.material-icons.volcano:before {
  content: "\ebda";
}
.material-icons.volume_down:before {
  content: "\e04d";
}
.material-icons.volume_down_alt:before {
  content: "\e79c";
}
.material-icons.volume_mute:before {
  content: "\e04e";
}
.material-icons.volume_off:before {
  content: "\e04f";
}
.material-icons.volume_up:before {
  content: "\e050";
}
.material-icons.volunteer_activism:before {
  content: "\ea70";
}
.material-icons.vpn_key:before {
  content: "\e0da";
}
.material-icons.vpn_key_off:before {
  content: "\eb7a";
}
.material-icons.vpn_lock:before {
  content: "\e62f";
}
.material-icons.vrpano:before {
  content: "\f082";
}
.material-icons.wallet:before {
  content: "\f8ff";
}
.material-icons.wallet_giftcard:before {
  content: "\e8f6";
}
.material-icons.wallet_membership:before {
  content: "\e8f7";
}
.material-icons.wallet_travel:before {
  content: "\e8f8";
}
.material-icons.wallpaper:before {
  content: "\e1bc";
}
.material-icons.warehouse:before {
  content: "\ebb8";
}
.material-icons.warning:before {
  content: "\e002";
}
.material-icons.warning_amber:before {
  content: "\f083";
}
.material-icons.wash:before {
  content: "\f1b1";
}
.material-icons.watch:before {
  content: "\e334";
}
.material-icons.watch_later:before {
  content: "\e924";
}
.material-icons.watch_off:before {
  content: "\eae3";
}
.material-icons.water:before {
  content: "\f084";
}
.material-icons.water_damage:before {
  content: "\f203";
}
.material-icons.water_drop:before {
  content: "\e798";
}
.material-icons.waterfall_chart:before {
  content: "\ea00";
}
.material-icons.waves:before {
  content: "\e176";
}
.material-icons.waving_hand:before {
  content: "\e766";
}
.material-icons.wb_auto:before {
  content: "\e42c";
}
.material-icons.wb_cloudy:before {
  content: "\e42d";
}
.material-icons.wb_incandescent:before {
  content: "\e42e";
}
.material-icons.wb_iridescent:before {
  content: "\e436";
}
.material-icons.wb_shade:before {
  content: "\ea01";
}
.material-icons.wb_sunny:before {
  content: "\e430";
}
.material-icons.wb_twighlight:before {
  content: "\ea02";
}
.material-icons.wb_twilight:before {
  content: "\e1c6";
}
.material-icons.wc:before {
  content: "\e63d";
}
.material-icons.web:before {
  content: "\e051";
}
.material-icons.web_asset:before {
  content: "\e069";
}
.material-icons.web_asset_off:before {
  content: "\e4f7";
}
.material-icons.web_stories:before {
  content: "\e595";
}
.material-icons.webhook:before {
  content: "\eb92";
}
.material-icons.wechat:before {
  content: "\ea81";
}
.material-icons.weekend:before {
  content: "\e16b";
}
.material-icons.west:before {
  content: "\f1e6";
}
.material-icons.whatsapp:before {
  content: "\ea9c";
}
.material-icons.whatshot:before {
  content: "\e80e";
}
.material-icons.wheelchair_pickup:before {
  content: "\f1ab";
}
.material-icons.where_to_vote:before {
  content: "\e177";
}
.material-icons.widgets:before {
  content: "\e1bd";
}
.material-icons.width_full:before {
  content: "\f8f5";
}
.material-icons.width_normal:before {
  content: "\f8f6";
}
.material-icons.width_wide:before {
  content: "\f8f7";
}
.material-icons.wifi:before {
  content: "\e63e";
}
.material-icons.wifi_1_bar:before {
  content: "\e4ca";
}
.material-icons.wifi_2_bar:before {
  content: "\e4d9";
}
.material-icons.wifi_calling:before {
  content: "\ef77";
}
.material-icons.wifi_calling_3:before {
  content: "\f085";
}
.material-icons.wifi_channel:before {
  content: "\eb6a";
}
.material-icons.wifi_find:before {
  content: "\eb31";
}
.material-icons.wifi_lock:before {
  content: "\e1e1";
}
.material-icons.wifi_off:before {
  content: "\e648";
}
.material-icons.wifi_password:before {
  content: "\eb6b";
}
.material-icons.wifi_protected_setup:before {
  content: "\f0fc";
}
.material-icons.wifi_tethering:before {
  content: "\e1e2";
}
.material-icons.wifi_tethering_error:before {
  content: "\ead9";
}
.material-icons.wifi_tethering_error_rounded:before {
  content: "\f086";
}
.material-icons.wifi_tethering_off:before {
  content: "\f087";
}
.material-icons.wind_power:before {
  content: "\ec0c";
}
.material-icons.window:before {
  content: "\f088";
}
.material-icons.wine_bar:before {
  content: "\f1e8";
}
.material-icons.woman:before {
  content: "\e13e";
}
.material-icons.woo_commerce:before {
  content: "\ea6d";
}
.material-icons.wordpress:before {
  content: "\ea9f";
}
.material-icons.work:before {
  content: "\e8f9";
}
.material-icons.work_history:before {
  content: "\ec09";
}
.material-icons.work_off:before {
  content: "\e942";
}
.material-icons.work_outline:before {
  content: "\e943";
}
.material-icons.workspace_premium:before {
  content: "\e7af";
}
.material-icons.workspaces:before {
  content: "\e1a0";
}
.material-icons.workspaces_filled:before {
  content: "\ea0d";
}
.material-icons.workspaces_outline:before {
  content: "\ea0f";
}
.material-icons.wrap_text:before {
  content: "\e25b";
}
.material-icons.wrong_location:before {
  content: "\ef78";
}
.material-icons.wysiwyg:before {
  content: "\f1c3";
}
.material-icons.yard:before {
  content: "\f089";
}
.material-icons.youtube_searched_for:before {
  content: "\e8fa";
}
.material-icons.zoom_in:before {
  content: "\e8ff";
}
.material-icons.zoom_in_map:before {
  content: "\eb2d";
}
.material-icons.zoom_out:before {
  content: "\e900";
}
.material-icons.zoom_out_map:before {
  content: "\e56b";
}