@import 'utilities/variables'

// Mat-select
mat-select-trigger
  color: $bbraun-gray-80

.mat-option-text
  color: $bbraun-gray-80

.mat-select-trigger .mat-select-arrow-wrapper
  visibility: hidden

.mat-select-value
  color: $bbraun-gray-80 !important

// datepicker
.mat-calendar-header .mat-icon-button
  border: 0 !important

.mat-calendar-header .mat-button
  border: 0 !important

.mat-card
  &:hover
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important

.mat-form-field-label-wrapper .mat-form-field-label
  line-height: 16px
